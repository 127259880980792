import { AgendaItem, Meeting } from '../../../graphql/types';
import {
  useLogAgendaMutation,
  useUnlogAgendaMutation,
} from '../gql/_gen_/meeting.gql';

import { useSaveMeeting } from './use-save-meeting';

export const useToggleAgenda = (meeting?: Meeting) => {
  const { handleSaveMeeting } = useSaveMeeting(meeting);
  const [logAgenda, { loading: logLoading }] = useLogAgendaMutation();
  const [unlogAgenda, { loading: unlogLoading }] = useUnlogAgendaMutation();

  const loading = logLoading || unlogLoading;

  const toggleAgendaItem = async (item: AgendaItem) => {
    if (meeting && meeting.id && meeting.agendaItems && item.id) {
      if (meeting.agendaItems.includes(item.id)) {
        await unlogAgenda({
          variables: {
            meetingId: meeting.id,
            agendaId: item.id,
          },
        });
      } else {
        await logAgenda({
          variables: {
            meetingId: meeting.id,
            agendaId: item.id,
          },
        });
      }
    } else if (!meeting?.id && item.id) {
      await handleSaveMeeting({
        agendaItems: [item.id],
      });
    }
  };

  return { loading, toggleAgendaItem };
};
