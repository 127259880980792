import * as HotelTeam from '../gql/_gen_/hotel-team.gql';
import * as Meetings from '../gql/_gen_/meeting.gql';

import { Maybe, TeamMember, TeamMemberActive } from '../../../graphql/types';
import { useEffect, useState } from 'react';

import { ExpandedTeamItem } from './expanded-team-item';
import { MeetingPanelsProps } from '../../reports/components/meeting-panels';
import { TeamListItem } from './team-list-item';
import { toast } from 'react-toastify';

interface ITeamView {
  meeting?: MeetingPanelsProps['meeting'];
  meetingTools?: MeetingPanelsProps['meetingTools'];
}

const TeamView = ({ meeting, meetingTools }: ITeamView) => {
  const [display, setDisplay] = useState<TeamMemberActive | 'all'>(
    TeamMemberActive.Active
  );
  const [expanded, setExpanded] = useState<Maybe<TeamMember>>();
  const [members, setMembers] = useState<TeamMember[]>();

  const [reactivateTeamMember] = HotelTeam.useReactivateTeamMemberMutation({
    refetchQueries: [Meetings.namedOperations.Query.MeetingTools],
  });

  const [removeTeamMember] = HotelTeam.useRemoveTeamMemberMutation({
    refetchQueries: [Meetings.namedOperations.Query.MeetingTools],
  });

  useEffect(() => {
    if (meetingTools?.hotelTeams && meetingTools?.hotelTeams.length > 0) {
      const hotelTeam = meetingTools?.hotelTeams as TeamMember[];

      if (display === 'all') {
        setMembers(hotelTeam);
      } else {
        setMembers(hotelTeam.filter((m) => m?.active === display));
      }
    }
  }, [display, meetingTools?.hotelTeams]);

  const handleToggleActive = async (member: TeamMember) => {
    if (member.active === TeamMemberActive.Active) {
      await removeTeamMember({
        variables: {
          removeTeamMemberId: String(member.id),
        },
        onCompleted: () =>
          toast.success('Team member removed', { position: 'top-left' }),
      });
    } else if (member.active === TeamMemberActive.Inactive) {
      await reactivateTeamMember({
        variables: {
          reactivateTeamMemberId: String(member.id),
        },
        onCompleted: () =>
          toast.success('Team member reactivated', { position: 'top-left' }),
      });
    }
  };

  return (
    <section className='px-3'>
      <div className='overflow-auto mt-3'>
        {expanded ? (
          <ExpandedTeamItem expanded={expanded} setExpanded={setExpanded} />
        ) : (
          <div className='flex flex-col'>
            <div className='bg-white w-full z-10 gap-4 pb-4'>
              <select
                className='ml-2 border border-gray-300 rounded-md p-0.5 text-sm'
                onChange={(e) =>
                  setDisplay(e.target.value as TeamMemberActive | 'all')
                }
                value={display}
              >
                <option value={TeamMemberActive.Active}>Active</option>
                <option value={TeamMemberActive.Inactive}>Inactive</option>
                <option value={'all'}>All</option>
              </select>
            </div>
            <ul className='divide-y divide-gray-200'>
              {members &&
                members.map((member) => (
                  <TeamListItem
                    data={member}
                    key={member.id}
                    meeting={meeting}
                    setExpanded={setExpanded}
                    toggleActive={handleToggleActive}
                  />
                ))}
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export { TeamView };
