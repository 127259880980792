import { Route, Switch } from 'react-router-dom';

import { OverviewSection } from '../overview-section';
import { TrackingSection } from '../tracking-section';

export const Routes = () => {
  return (
    <Switch>
      <Route path='/admin/overview' component={OverviewSection} exact />
      <Route path='/admin/tracking' component={TrackingSection} exact />
    </Switch>
  );
};
