import {
  EventWidgetQuery,
  useEventWidgetQuery,
} from '../gql/_gen_/hotel-events-widget.gql';
import {
  OtaiLowestRatesQuery,
  useOtaiLowestRatesQuery,
} from '../../overview-table/gql/_gen_/rate-shop.gql';
import { ReportDataQuery, useReportDataQuery } from '../gql/_gen_/report.gql';
import {
  RmRecsWidgetQuery,
  useRmRecsWidgetQuery,
} from '../gql/_gen_/rm-recs-widget.gql';
import { createContext, useContext } from 'react';

import { DateHelpers } from '../../../helpers/dateHelpers';
import { defaultSubId } from '../../../reducers/useRateShopParams';
import { some } from 'lodash';
import { useDashboard } from '../../../context/dashboardContext';
import { useHotel } from '../../../context/hotelContext';
import { useIsShared } from '../hooks/use-report-location';
import { useMeeting } from '../../meetings/context/meeting-context';

type ReportDataContextProps = {
  data: ReportDataType;
  loading: boolean;
};

export type ReportDataType = {
  dataEvents?: EventWidgetQuery;
  dataRateShop?: OtaiLowestRatesQuery;
  dataReport?: ReportDataQuery;
  dataRmRecs?: RmRecsWidgetQuery;
  loadingEvents: boolean;
  loadingRateShop: boolean;
  loadingReport: boolean;
  loadingRmRecs: boolean;
};

type ReportDataProviderProps = { children: React.ReactNode };

const ReportDataContext = createContext<ReportDataContextProps | undefined>(
  undefined
);

function ReportDataProvider({ children }: ReportDataProviderProps) {
  const { meeting } = useMeeting();
  const { brandCode, hotel } = useDashboard();
  const { otaiSubs } = useHotel();
  const { isShared } = useIsShared();

  const coreVariables = {
    brandCode: brandCode!,
    snapshotDate: meeting?.meetingDate || DateHelpers.today(),
    useCache: false,
  };

  const { data: dataEvents, loading: loadingEvents } = useEventWidgetQuery({
    skip: !hotel || !hotel.hotel_id || !hotel.brand_code || isShared,
    variables: {
      hotelId: hotel?.hotel_id || hotel?.brand_code,
      startDate: DateHelpers.subtract(
        meeting?.meetingDate || DateHelpers.today(),
        'days',
        7
      ),
      endDate: DateHelpers.subtract(
        meeting?.meetingDate || DateHelpers.today(),
        'days',
        -365
      ),
    },
  });

  const { data: dataRmRecs, loading: loadingRmRecs } = useRmRecsWidgetQuery({
    skip: !hotel || !hotel.hotel_id || !hotel.brand_code || isShared,
    variables: {
      filters: {
        hotelId: hotel?.hotel_id || hotel?.brand_code,
      },
    },
  });

  const { data: dataRateShop, loading: loadingRateShop } =
    useOtaiLowestRatesQuery({
      skip: !brandCode || !otaiSubs || isShared,
      variables: {
        params: {
          subscriptionId: defaultSubId(otaiSubs) as string,
          los: 1,
          bar: true,
          ota: 'branddotcom',
          roomType: 'all',
          shopLength: 365,
          changeDays: [1],
        },
        brandCode: brandCode || '',
      },
    });

  // We need to compare the first of the year vs the first of the previous month
  // relative to the meeting date and pick the one that goes further back.

  const firstOfYear = DateHelpers.firstOfYear(
    meeting?.meetingDate || DateHelpers.today()
  );
  const firstOfPrevMonth = DateHelpers.firstOfMonth({
    date: DateHelpers.subtract(
      meeting?.meetingDate || DateHelpers.today(),
      'days',
      30
    ),
    daysOut: 0,
  });

  const foyIsBefore = DateHelpers.isBefore(firstOfYear, firstOfPrevMonth);

  const startDate = foyIsBefore ? firstOfYear : firstOfPrevMonth;

  const { data: dataReport, loading: loadingReport } = useReportDataQuery({
    skip: !brandCode || !hotel?.hotel_id || !hotel.brand_code || isShared,
    variables: {
      brandCode,
      snapshotDate: meeting?.meetingDate || DateHelpers.today(),
      args: {
        brandCode,
        snapshotDate: meeting?.meetingDate || DateHelpers.today(),
        startDate,
        endDate: DateHelpers.subtract(
          meeting?.meetingDate || DateHelpers.today(),
          'days',
          -365
        ),
      },
      currentRateTableArgs2: {
        ...coreVariables,
        startDate,
        endDate: DateHelpers.subtract(
          meeting?.meetingDate || DateHelpers.today(),
          'days',
          -365
        ),
      },
      filters: {
        startDate,
        endDate: DateHelpers.subtract(
          meeting?.meetingDate || DateHelpers.today(),
          'days',
          -365
        ),
        hotelId: hotel?.hotel_id || hotel?.brand_code,
      },
      hotelId: hotel?.hotel_id || hotel?.brand_code,
      startDate,
      endDate: DateHelpers.subtract(
        meeting?.meetingDate || DateHelpers.today(),
        'days',
        -365
      ),
      groupDailyStartDate2: meeting?.meetingDate || DateHelpers.today(),
      groupDailyEndDate2: DateHelpers.subtract(
        meeting?.meetingDate || DateHelpers.today(),
        'days',
        -365
      ),
    },
  });

  const data = {
    dataEvents,
    dataRateShop,
    dataReport,
    dataRmRecs,
    loadingEvents,
    loadingRateShop,
    loadingReport,
    loadingRmRecs,
  };

  const loading = some(
    [loadingEvents, loadingRateShop, loadingReport, loadingRmRecs],
    Boolean
  );

  const value = {
    data,
    loading,
  };

  return (
    <ReportDataContext.Provider value={value}>
      {children}
    </ReportDataContext.Provider>
  );
}

function useReportData() {
  const context = useContext(ReportDataContext);
  if (context === undefined) {
    throw new Error('useReportData must be used within a ReportDataProvider');
  }
  return context;
}

export { ReportDataProvider, useReportData };
