import { kebabCase } from 'lodash';

interface ISectionContainer {
  anchorId?: string;
  children: React.ReactNode;
  title?: string | JSX.Element;
  toolbar?: JSX.Element | null;
}

const SectionContainer = ({
  anchorId,
  children,
  title,
  toolbar,
}: ISectionContainer) => {
  return (
    <section
      id={anchorId}
      aria-labelledby={kebabCase(
        typeof title === 'string' ? title : 'report-section'
      )}
    >
      <div className='rounded-lg grid-cols-1 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:gap-px'>
        {title ? (
          <div className='bg-blue-900 border-none flex items-center'>
            <div className='text-white text-left p-2'>{title}</div>
            {toolbar ? <span>{toolbar}</span> : null}
          </div>
        ) : null}
        <>{children}</>
      </div>
    </section>
  );
};

export { SectionContainer };
