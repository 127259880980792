/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HeatMapRmDataQueryVariables = Types.Exact<{
  args: Types.RmDataArgs;
}>;


export type HeatMapRmDataQuery = { __typename?: 'Query', rmData?: Array<{ __typename?: 'RmData', capacity?: number | null, compadr_py?: number | null, compocc_py?: number | null, comprevpar_py?: number | null, delta_1day?: number | null, delta_3day?: number | null, delta_7day?: number | null, group_blocked?: number | null, group_sold?: number | null, lvl_a_occ?: number | null, lvl_a_remaining?: number | null, lvl_a_sold?: number | null, lvl_b_occ?: number | null, lvl_b_remaining?: number | null, lvl_b_sold?: number | null, lvl_c_occ?: number | null, lvl_c_remaining?: number | null, lvl_c_sold?: number | null, lvl_d_occ?: number | null, lvl_d_remaining?: number | null, lvl_d_sold?: number | null, lvl_e_occ?: number | null, lvl_e_remaining?: number | null, lvl_e_sold?: number | null, lvl_f_occ?: number | null, lvl_f_remaining?: number | null, lvl_f_sold?: number | null, lvl_g_occ?: number | null, lvl_g_remaining?: number | null, lvl_g_sold?: number | null, lvl_h_occ?: number | null, lvl_h_remaining?: number | null, lvl_h_sold?: number | null, ooo?: number | null, pu_dayofarr?: number | null, rate?: number | null, rooms_committed?: number | null, sold?: number | null, stay_date?: any | null, total_capacity?: number | null, total_potential_group?: number | null, total_sold?: number | null, transient_sold?: number | null } | null> | null };

export type HeatMapBestRevQueryVariables = Types.Exact<{
  brandCode?: Types.InputMaybe<Types.Scalars['String']>;
  snapshotDate?: Types.InputMaybe<Types.Scalars['String']>;
  useCache?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type HeatMapBestRevQuery = { __typename?: 'Query', bestrevData?: Array<{ __typename?: 'BestrevData', market_rate?: number | null, recommended_rate?: number | null, stay_date?: string | null } | null> | null };

export type HeatRoomDataQueryVariables = Types.Exact<{
  brandCode?: Types.InputMaybe<Types.Scalars['String']>;
  snapshotDate?: Types.InputMaybe<Types.Scalars['String']>;
  startDate?: Types.InputMaybe<Types.Scalars['String']>;
  endDate?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type HeatRoomDataQuery = { __typename?: 'Query', roomData?: Array<{ __typename?: 'Room', room_code?: string | null, rt_room_allocation?: number | null, rt_description?: string | null, rt_flex_rate?: number | null, rt_occ?: number | null, rt_remaining?: number | null, rt_sold?: number | null, rt_status?: string | null, stay_date?: any | null } | null> | null };


export const HeatMapRmDataDocument = gql`
    query HeatMapRmData($args: RmDataArgs!) {
  rmData(args: $args) {
    capacity
    compadr_py
    compocc_py
    comprevpar_py
    delta_1day
    delta_3day
    delta_7day
    group_blocked
    group_sold
    lvl_a_occ
    lvl_a_remaining
    lvl_a_sold
    lvl_b_occ
    lvl_b_remaining
    lvl_b_sold
    lvl_c_occ
    lvl_c_remaining
    lvl_c_sold
    lvl_d_occ
    lvl_d_remaining
    lvl_d_sold
    lvl_e_occ
    lvl_e_remaining
    lvl_e_sold
    lvl_f_occ
    lvl_f_remaining
    lvl_f_sold
    lvl_g_occ
    lvl_g_remaining
    lvl_g_sold
    lvl_h_occ
    lvl_h_remaining
    lvl_h_sold
    ooo
    pu_dayofarr
    rate
    rooms_committed
    sold
    stay_date
    total_capacity
    total_potential_group
    total_sold
    transient_sold
  }
}
    `;

/**
 * __useHeatMapRmDataQuery__
 *
 * To run a query within a React component, call `useHeatMapRmDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeatMapRmDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeatMapRmDataQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useHeatMapRmDataQuery(baseOptions: Apollo.QueryHookOptions<HeatMapRmDataQuery, HeatMapRmDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeatMapRmDataQuery, HeatMapRmDataQueryVariables>(HeatMapRmDataDocument, options);
      }
export function useHeatMapRmDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeatMapRmDataQuery, HeatMapRmDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeatMapRmDataQuery, HeatMapRmDataQueryVariables>(HeatMapRmDataDocument, options);
        }
export type HeatMapRmDataQueryHookResult = ReturnType<typeof useHeatMapRmDataQuery>;
export type HeatMapRmDataLazyQueryHookResult = ReturnType<typeof useHeatMapRmDataLazyQuery>;
export type HeatMapRmDataQueryResult = Apollo.QueryResult<HeatMapRmDataQuery, HeatMapRmDataQueryVariables>;
export const HeatMapBestRevDocument = gql`
    query HeatMapBestRev($brandCode: String, $snapshotDate: String, $useCache: Boolean) {
  bestrevData(
    brandCode: $brandCode
    snapshotDate: $snapshotDate
    useCache: $useCache
  ) {
    market_rate
    recommended_rate
    stay_date
  }
}
    `;

/**
 * __useHeatMapBestRevQuery__
 *
 * To run a query within a React component, call `useHeatMapBestRevQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeatMapBestRevQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeatMapBestRevQuery({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      snapshotDate: // value for 'snapshotDate'
 *      useCache: // value for 'useCache'
 *   },
 * });
 */
export function useHeatMapBestRevQuery(baseOptions?: Apollo.QueryHookOptions<HeatMapBestRevQuery, HeatMapBestRevQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeatMapBestRevQuery, HeatMapBestRevQueryVariables>(HeatMapBestRevDocument, options);
      }
export function useHeatMapBestRevLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeatMapBestRevQuery, HeatMapBestRevQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeatMapBestRevQuery, HeatMapBestRevQueryVariables>(HeatMapBestRevDocument, options);
        }
export type HeatMapBestRevQueryHookResult = ReturnType<typeof useHeatMapBestRevQuery>;
export type HeatMapBestRevLazyQueryHookResult = ReturnType<typeof useHeatMapBestRevLazyQuery>;
export type HeatMapBestRevQueryResult = Apollo.QueryResult<HeatMapBestRevQuery, HeatMapBestRevQueryVariables>;
export const HeatRoomDataDocument = gql`
    query HeatRoomData($brandCode: String, $snapshotDate: String, $startDate: String, $endDate: String) {
  roomData(
    brandCode: $brandCode
    snapshotDate: $snapshotDate
    startDate: $startDate
    endDate: $endDate
  ) {
    room_code
    rt_room_allocation
    rt_description
    rt_flex_rate
    rt_occ
    rt_remaining
    rt_sold
    rt_status
    stay_date
  }
}
    `;

/**
 * __useHeatRoomDataQuery__
 *
 * To run a query within a React component, call `useHeatRoomDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeatRoomDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeatRoomDataQuery({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      snapshotDate: // value for 'snapshotDate'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useHeatRoomDataQuery(baseOptions?: Apollo.QueryHookOptions<HeatRoomDataQuery, HeatRoomDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeatRoomDataQuery, HeatRoomDataQueryVariables>(HeatRoomDataDocument, options);
      }
export function useHeatRoomDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeatRoomDataQuery, HeatRoomDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeatRoomDataQuery, HeatRoomDataQueryVariables>(HeatRoomDataDocument, options);
        }
export type HeatRoomDataQueryHookResult = ReturnType<typeof useHeatRoomDataQuery>;
export type HeatRoomDataLazyQueryHookResult = ReturnType<typeof useHeatRoomDataLazyQuery>;
export type HeatRoomDataQueryResult = Apollo.QueryResult<HeatRoomDataQuery, HeatRoomDataQueryVariables>;
export const namedOperations = {
  Query: {
    HeatMapRmData: 'HeatMapRmData',
    HeatMapBestRev: 'HeatMapBestRev',
    HeatRoomData: 'HeatRoomData'
  }
}