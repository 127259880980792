import { BiNotepad } from 'react-icons/bi';

interface BtnInsertNotepadProps {
  handleInsert: () => void;
}

export const BtnInsertNotepad = ({ handleInsert }: BtnInsertNotepadProps) => {
  return (
    <button
      onClick={handleInsert}
      data-for='notes-toolbar-tooltip'
      data-tip='Insert notes from your notepad'
    >
      <BiNotepad />
    </button>
  );
};
