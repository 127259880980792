import { DATE_SEGMENT_FIELDS } from './fragments';
import { gql } from '@apollo/client';

export const GET_BESTREV = gql`
  query BestrevData($brandCode: String, $snapshotDate: String, $useCache: Boolean) {
    bestrevData(brandCode: $brandCode, snapshotDate: $snapshotDate, useCache: $useCache) {
      stay_date
      ${DATE_SEGMENT_FIELDS}
      recommended_rate
      market_rate
    }
  }
`;
