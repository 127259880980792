import {
  addColumns,
  colsGroup,
  colsTransient,
  removeColumns,
  removeHotelSpecificColumns,
  simpleColumnMap,
  tableOverview,
  varLyColumns,
  varLyFColumns,
  varLyFNColumns,
  varLyNColumns,
} from '../../../dashboards/tables/overview/tableOverview';
import { getValue, saveValue } from '../../../helpers/localStorageHelper';

import { ColumnBase } from '../../../types/Columns';
import { FixMeLater } from '../../../types/FixMeLaterType';
import { useReducer } from 'react';

// Changing the version number will reset all settings.
// Only change the version number when we make a change to the settings that
// require a user to reset their settings. Items like changing the column names, etc.
const SETTINGS_VERSION = 2;

export type Action = {
  brandCode?: string;
  enabled?: boolean;
  fetchers?: DataFetch;
  hotelCols?: FixMeLater;
  key: string;
  payload?: {
    colLock?: number;
    location?: {
      position: string | number;
      where: 'after' | 'before' | 'index';
    };
    useCache?: boolean;
    visible?: boolean;
    showSuperscript?: boolean;
    toolbar?: boolean;
    year?: number;
  };
  type?: string;
};

export type ColumnSettings = {
  visible: boolean;
  toolbar?: boolean;
  location: {
    where: 'after' | 'before' | 'index';
    position: string | number;
  };
  showSuperscript?: boolean;
};

export type DataFetch = {
  weather: FixMeLater;
};

type Settings = {
  [brandCode: string]: HotelSettings;
};

export type HotelSettings = {
  version: number;
  all: boolean;
  colLock: number;
  forecasts: ColumnSettings;
  group: ColumnSettings;
  gtdCxl: ColumnSettings;
  liveData: ColumnSettings;
  pickup: ColumnSettings;
  pickupDoa: ColumnSettings;
  rateLevelStatus: ColumnSettings;
  rates: ColumnSettings;
  rooms: ColumnSettings;
  str: ColumnSettings;
  transient: ColumnSettings;
  varLy: boolean;
  varLyF: boolean;
  varLyFN: boolean;
  varLyN: boolean;
  weather: ColumnSettings;
  tableCols: ColumnBase[];
  useCache?: boolean;
};

const addVarCols = (varCols: ColumnBase[], baseCols: ColumnBase[]) => {
  let newCols = [...baseCols];
  varCols.forEach((col) => {
    const position = col.key.includes('current_rate')
      ? 'current_rate'
      : col.key.split('_')[1];

    const where = col.key.includes('current_rate') ? 'before' : 'after';
    newCols = addColumns([col], newCols, {
      where,
      position,
    });
  });
  return newCols;
};

const initTableCols = () => {
  let newCols = [...tableOverview];
  const varColGroup = [varLyFColumns, varLyColumns];
  varColGroup.forEach((varCols) => {
    newCols = addVarCols(varCols, newCols);
  });
  return newCols;
};

const newHotelSettings: HotelSettings = {
  version: SETTINGS_VERSION,
  all: false,
  colLock: 5,
  forecasts: {
    visible: false,
    location: {
      where: 'after',
      position: 'current_rate',
    },
    showSuperscript: false,
  },
  group: {
    visible: false,
    location: {
      where: 'after',
      position: 'group_blocked',
    },
  },
  gtdCxl: {
    visible: false,
    location: {
      where: 'after',
      position: 'current_rate',
    },
  },
  liveData: {
    visible: false,
    location: {
      where: 'after',
      position: 'rt_rooms_committed',
    },
  },
  pickup: {
    visible: false,
    location: {
      where: 'after',
      position: 'delta_7day',
    },
  },
  pickupDoa: {
    visible: false,
    location: {
      where: 'after',
      position: 'delta_7day',
    },
  },
  rateLevelStatus: {
    visible: false,
    location: {
      where: 'after',
      position: 'note_ly.content',
    },
  },
  rates: {
    visible: false,
    location: {
      where: 'after',
      position: 'recommended_rate',
    },
  },
  rooms: {
    visible: false,
    location: {
      where: 'after',
      position: 'note_ly.content',
    },
  },
  str: {
    visible: false,
    location: {
      where: 'after',
      position: 'occ',
    },
  },
  transient: {
    visible: false,
    location: {
      where: 'after',
      position: 'sold',
    },
  },
  varLy: true,
  varLyF: true,
  varLyFN: false,
  varLyN: false,
  weather: {
    visible: false,
    location: {
      where: 'after',
      position: 'note_ly.content',
    },
  },
  tableCols: initTableCols(),
  useCache: true,
};

const adjustYearHeaders = (year = 3, hotelSettings: HotelSettings) => {
  let newCols: ColumnBase[] = [];

  // Manage these in reverse order so they always end up LY, LYF, LYN, LYFN
  const varColGroup = [
    varLyFNColumns(year as number),
    varLyNColumns(year as number),
    varLyFColumns,
    varLyColumns,
  ];

  const groupTransientCols = [
    colsGroup(year as number),
    colsTransient(year as number),
  ];

  const visStatus = [
    hotelSettings.varLyFN,
    hotelSettings.varLyN,
    hotelSettings.varLyF,
    hotelSettings.varLy,
  ];

  // Clear out var columns if added already
  newCols = removeColumns(varColGroup.concat(groupTransientCols).flat(), [
    ...hotelSettings.tableCols,
  ]);

  varColGroup.forEach((varCols, idx) => {
    const isVisible = visStatus[idx];
    if (isVisible) {
      newCols = addVarCols(varCols, newCols);
    }
  });

  if (hotelSettings.group.visible) {
    newCols = addColumns(
      colsGroup(year as number),
      newCols,
      hotelSettings.group.location
    );
  }

  if (hotelSettings.transient.visible) {
    newCols = addColumns(
      colsTransient(year as number),
      newCols,
      hotelSettings.transient.location
    );
  }

  return newCols;
};

const process = (
  state: Settings,
  hotelSettings: HotelSettings,
  brandCode: string
) => {
  store(hotelSettings, brandCode);
  return { ...state, [brandCode]: hotelSettings };
};

const retrieve = (brandCode: string, year?: number) => {
  const hotelSettings = JSON.parse(
    getValue(`TableSettings_${brandCode}`) as string
  );
  if (!hotelSettings) {
    return;
  }
  if (!hotelSettings.version) {
    return;
  }
  if (hotelSettings.version !== SETTINGS_VERSION) {
    return;
  }
  if (hotelSettings) {
    const newCols = adjustYearHeaders(year, hotelSettings);
    return {
      ...hotelSettings,
      tableCols: newCols,
    };
  }
  return hotelSettings;
};

const toggledSettings = (
  currentSettings: HotelSettings,
  key: string,
  columnSettings: ColumnSettings,
  tableCols: ColumnBase[],
  visible?: boolean
) => {
  const updatedSettings = {
    ...currentSettings,
    [key]: key.includes('var') ? visible : { ...columnSettings, visible },
    tableCols,
  };

  return updatedSettings;
};

export default function settingsReducer(state: Settings, action: Action) {
  let baseCols: ColumnBase[] = [];
  let newCols: ColumnBase[] = [];
  const { brandCode, fetchers, hotelCols, payload } = action;
  const {
    colLock,
    location,
    showSuperscript,
    toolbar,
    useCache,
    visible,
    year,
  } = payload || {};
  if (brandCode) {
    const hotelSettings =
      state[brandCode] || retrieve(brandCode, year) || newHotelSettings;
    const settings = hotelSettings[
      action.key as keyof HotelSettings
    ] as ColumnSettings;
    let updatedSettings: HotelSettings = { ...hotelSettings };

    switch (action.type) {
      case 'CACHE':
        updatedSettings = { ...hotelSettings, useCache: useCache as boolean };
        return process(state, updatedSettings, brandCode);
      case 'COL_LOCK':
        updatedSettings = { ...hotelSettings, colLock: colLock as number };
        return process(state, updatedSettings, brandCode);
      case 'LOCATION':
        updatedSettings = {
          ...hotelSettings,
          [action.key]: { ...settings, location },
        };
        return process(state, updatedSettings, brandCode);
      case 'SUPERSCRIPT':
        updatedSettings = {
          ...hotelSettings,
          [action.key]: { ...settings, showSuperscript },
        };
        return process(state, updatedSettings, brandCode);
      case 'TOOLBAR':
        updatedSettings = {
          ...hotelSettings,
          [action.key]: { ...settings, toolbar },
        };
        return process(state, updatedSettings, brandCode);
      case 'TOGGLE':
        switch (true) {
          case ['rates', 'rooms'].includes(action.key):
            baseCols = removeHotelSpecificColumns(
              [...hotelSettings.tableCols],
              action.key as 'rates' | 'rooms'
            );
            if (hotelCols) {
              if (visible) {
                newCols = addColumns(hotelCols, baseCols, settings.location);
                updatedSettings = toggledSettings(
                  hotelSettings,
                  action.key,
                  settings,
                  newCols,
                  visible
                );
                return process(state, updatedSettings, brandCode);
              }
              updatedSettings = toggledSettings(
                hotelSettings,
                action.key,
                settings,
                baseCols,
                visible
              );
              return process(state, updatedSettings, brandCode);
            }
            updatedSettings = toggledSettings(
              hotelSettings,
              action.key,
              settings,
              baseCols,
              visible
            );
            return process(state, updatedSettings, brandCode);
          case action.key.includes('group'):
            baseCols = removeColumns(colsGroup(year as number), [
              ...hotelSettings.tableCols,
            ]);

            if (visible) {
              newCols = addColumns(
                colsGroup(year as number),
                baseCols,
                settings.location
              );
              updatedSettings = toggledSettings(
                hotelSettings,
                action.key,
                settings,
                newCols,
                visible
              );

              return process(state, updatedSettings, brandCode);
            }

            updatedSettings = toggledSettings(
              hotelSettings,
              action.key,
              settings,
              baseCols,
              visible
            );
            return process(state, updatedSettings, brandCode);
          case action.key.includes('transient'):
            baseCols = removeColumns(colsTransient(year as number), [
              ...hotelSettings.tableCols,
            ]);
            if (visible) {
              newCols = addColumns(
                colsTransient(year as number),
                baseCols,
                settings.location
              );
              updatedSettings = toggledSettings(
                hotelSettings,
                action.key,
                settings,
                newCols,
                visible
              );
              return process(state, updatedSettings, brandCode);
            }
            updatedSettings = toggledSettings(
              hotelSettings,
              action.key,
              settings,
              baseCols,
              visible
            );
            return process(state, updatedSettings, brandCode);
          case action.key.includes('var'):
            // Manage these in reverse order so they always end up LY, LYF, LYN, LYFN
            const varColGroup = [
              varLyFNColumns(year as number),
              varLyNColumns(year as number),
              varLyFColumns,
              varLyColumns,
            ];

            const visStatus = varVisStatus(hotelSettings, action);
            // Clear out var columns if added already
            newCols = removeColumns(varColGroup.flat(), [
              ...hotelSettings.tableCols,
            ]);
            varColGroup.forEach((varCols, idx) => {
              const isVisible = visStatus[idx];
              if (isVisible) {
                newCols = addVarCols(varCols, newCols);
              }
            });
            updatedSettings = toggledSettings(
              hotelSettings,
              action.key,
              settings,
              newCols,
              visible
            );
            return process(state, updatedSettings, brandCode);
          default:
            baseCols = removeColumns(simpleColumnMap[action.key], [
              ...hotelSettings.tableCols,
            ]);
            if (visible) {
              newCols = addColumns(
                simpleColumnMap[action.key],
                baseCols,
                settings.location
              );
              updatedSettings = toggledSettings(
                hotelSettings,
                action.key,
                settings,
                newCols,
                visible
              );
              return process(state, updatedSettings, brandCode);
            }
            updatedSettings = toggledSettings(
              hotelSettings,
              action.key,
              settings,
              baseCols,
              visible
            );
            return process(state, updatedSettings, brandCode);
        }
      case 'TOGGLE_ALL':
        Object.keys(updatedSettings).forEach((key) => {
          if (key !== 'tableCols') {
            const newSettings = updatedSettings[
              key as keyof HotelSettings
            ] as ColumnSettings;
            // ***** Fetch data ***** \\
            if (key !== 'all' && visible === true) {
              // fetch data
              if (fetchers && fetchers[key as keyof DataFetch]) {
                fetchers[key as keyof DataFetch]();
              }
            }

            // ***** Update the visible booleans ***** \\
            let stateValue = updatedSettings[key as keyof HotelSettings];
            if (typeof stateValue === 'boolean') {
              if (key === 'useCache') {
                stateValue = hotelSettings.useCache;
              } else {
                stateValue = visible as boolean;
              }

              updatedSettings = { ...updatedSettings, [key]: stateValue };
            } else if (typeof stateValue === 'object') {
              stateValue = { ...stateValue, visible: visible as boolean };
              updatedSettings = { ...updatedSettings, [key]: stateValue };
            }

            // ***** Update the tableCols object ***** \\
            switch (true) {
              case ['rates', 'rooms'].includes(key):
                baseCols = removeHotelSpecificColumns(
                  [...updatedSettings.tableCols],
                  key as 'rates' | 'rooms'
                );
                if (hotelCols) {
                  if (visible) {
                    newCols = addColumns(
                      hotelCols,
                      baseCols,
                      newSettings.location
                    );
                    updatedSettings = {
                      ...updatedSettings,
                      tableCols: newCols,
                    };
                  } else {
                    updatedSettings = {
                      ...updatedSettings,
                      tableCols: baseCols,
                    };
                  }
                } else {
                  updatedSettings = { ...updatedSettings, tableCols: baseCols };
                }
                break;
              case ['group'].includes(key):
                baseCols = removeColumns(colsGroup(year as number), [
                  ...hotelSettings.tableCols,
                ]);
                if (colsGroup(year as number)) {
                  if (visible) {
                    newCols = addColumns(
                      colsGroup(year as number),
                      baseCols,
                      newSettings.location
                    );
                    updatedSettings = {
                      ...updatedSettings,
                      tableCols: newCols,
                    };
                  } else {
                    updatedSettings = {
                      ...updatedSettings,
                      tableCols: baseCols,
                    };
                  }
                } else {
                  updatedSettings = { ...updatedSettings, tableCols: baseCols };
                }
                break;
              case ['transient'].includes(key):
                baseCols = removeColumns(colsTransient(year as number), [
                  ...hotelSettings.tableCols,
                ]);
                if (colsTransient(year as number)) {
                  if (visible) {
                    newCols = addColumns(
                      colsTransient(year as number),
                      baseCols,
                      newSettings.location
                    );
                    updatedSettings = {
                      ...updatedSettings,
                      tableCols: newCols,
                    };
                  } else {
                    updatedSettings = {
                      ...updatedSettings,
                      tableCols: baseCols,
                    };
                  }
                } else {
                  updatedSettings = { ...updatedSettings, tableCols: baseCols };
                }
                break;
              case key.includes('var'):
                // Manage these in reverse order so they always end up LY, LYF, LYN, LYFN
                const varColGroup = [
                  varLyFNColumns(year as number),
                  varLyNColumns(year as number),
                  varLyFColumns,
                  varLyColumns,
                ];
                const visStatus = varVisStatus(updatedSettings, {
                  brandCode,
                  key,
                  payload: { visible },
                  type: 'var',
                });
                // Clear out var columns if added already
                newCols = removeColumns(varColGroup.flat(), [
                  ...updatedSettings.tableCols,
                ]);
                varColGroup.forEach((varCols, idx) => {
                  const isVisible = visStatus[idx];
                  if (isVisible) {
                    newCols = addVarCols(varCols, newCols);
                  }
                });
                updatedSettings = { ...updatedSettings, tableCols: newCols };
                break;
              default:
                if (
                  key !== 'all' &&
                  key !== 'colLock' &&
                  key !== 'reset_settings'
                ) {
                  baseCols = removeColumns(simpleColumnMap[key], [
                    ...updatedSettings.tableCols,
                  ]);
                  if (visible) {
                    newCols = addColumns(
                      simpleColumnMap[key],
                      baseCols,
                      newSettings.location
                    );
                    updatedSettings = {
                      ...updatedSettings,
                      tableCols: newCols,
                    };
                  } else {
                    updatedSettings = {
                      ...updatedSettings,
                      tableCols: baseCols,
                    };
                  }
                }
            }
          }
        });
        return process(state, updatedSettings, brandCode);
      case 'RESET_HOTEL_COLS':
        newCols = removeHotelSpecificColumns([...hotelSettings.tableCols]);
        updatedSettings = { ...hotelSettings, tableCols: newCols };
        return process(state, updatedSettings, brandCode);
      case 'RESET_SETTINGS':
        return process(state, newHotelSettings, brandCode);
      case 'YEAR_CHANGE':
        newCols = adjustYearHeaders(year, hotelSettings);

        updatedSettings = {
          ...hotelSettings,
          [action.key]: visible,
          tableCols: newCols,
        };

        return process(state, updatedSettings, brandCode);
      default:
        return process(state, hotelSettings, brandCode);
    }
  } else {
    return state;
  }
}

const store = (newHotelSettings: HotelSettings, brandCode: string) => {
  saveValue(`TableSettings_${brandCode}`, JSON.stringify(newHotelSettings));
};

const varVisStatus = (hotelSettings: HotelSettings, action: Action) => {
  switch (action.key) {
    case 'varLy':
      return [
        hotelSettings.varLyFN,
        hotelSettings.varLyN,
        hotelSettings.varLyF,
        action.payload?.visible,
      ];
    case 'varLyF':
      return [
        hotelSettings.varLyFN,
        hotelSettings.varLyN,
        action.payload?.visible,
        hotelSettings.varLy,
      ];
    case 'varLyFN':
      return [
        action.payload?.visible,
        hotelSettings.varLyN,
        hotelSettings.varLyF,
        hotelSettings.varLy,
      ];
    case 'varLyN':
      return [
        hotelSettings.varLyFN,
        action.payload?.visible,
        hotelSettings.varLyF,
        hotelSettings.varLy,
      ];
    default:
      return [
        hotelSettings.varLyFN,
        hotelSettings.varLyN,
        hotelSettings.varLyF,
        hotelSettings.varLy,
      ];
  }
};

export function useTableSettings() {
  return useReducer(settingsReducer, {});
}
