import { AgendaItem, Maybe, Meeting } from '../../../graphql/types';
import { useEffect, useState } from 'react';

import { MdOutlineDeleteOutline } from 'react-icons/md';
import { useIsShared } from '../../reports/hooks/use-report-location';
import { useToggleAgenda } from '../hooks/use-toggle-agenda';
import { useTrackMeetingEvent } from '../../tracking/hooks/use-track-event';

type AgendaListItemProps = {
  data: Maybe<AgendaItem>;
  handleDelete: (itemId: string) => void;
  meeting?: Meeting;
  setExpanded: React.Dispatch<
    React.SetStateAction<AgendaItem | null | undefined>
  >;
  itemNotes?: string | null;
};

const AgendaListItem = ({
  data,
  handleDelete,
  meeting,
  setExpanded,
  itemNotes,
}: AgendaListItemProps) => {
  const { isShared } = useIsShared();
  const { loading, toggleAgendaItem } = useToggleAgenda(meeting);
  const [reviewed, setReviewed] = useState(false);
  const track = useTrackMeetingEvent({ meeting, page: 'agenda_list_item' });

  useEffect(() => {
    if (meeting?.agendaItems && data?.id && !loading) {
      const isReviewed = meeting.agendaItems.includes(data.id);
      if (isReviewed !== reviewed) {
        setReviewed(isReviewed);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, meeting?.agendaItems]);

  const handleReviewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (data) {
      setReviewed(e.target.checked);
      toggleAgendaItem(data);
      track('agenda_item_reviewed', `agendaId_${data.id}`);
    }
  };

  return (
    <li key={data?.id} className='py-5'>
      <div className='relative'>
        <div className='flex justify-between'>
          <div className='flex mr-2'>
            <h3 className='text-sm font-semibold text-gray-800'>
              <button disabled={isShared} onClick={() => setExpanded(data)}>
                {data?.name}
              </button>
            </h3>
            {!data?.isDefault && (
              <button
                className='hover:text-red-500 ml-1.5'
                disabled={isShared}
                onClick={() => handleDelete(data?.id as string)}
              >
                <MdOutlineDeleteOutline />
              </button>
            )}
          </div>
          <input
            type='checkbox'
            checked={reviewed}
            disabled={isShared}
            onChange={handleReviewChange}
          />
        </div>
        {itemNotes ? (
          <span className='text-xs text-gray-600'>{itemNotes}</span>
        ) : null}
      </div>
    </li>
  );
};

export { AgendaListItem };
