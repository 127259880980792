import { Actions, DataRow } from '../../types/DataTable';
import {
  State as Checked,
  Action as CheckedAction,
} from '../../reducers/useBulkCheckboxes';
import { useEffect, useRef, useState } from 'react';

import { MdDeleteForever } from 'react-icons/md';
import { displayDate } from '../../helpers/dateHelpers';

type Props = {
  actions?: Actions;
  attr: string;
  checked: Checked;
  options?: { name: string; value: string }[];
  readOnly?: boolean;
  row: DataRow;
  setChecked: React.Dispatch<CheckedAction>;
  type: string;
};

const DTCell = ({
  actions,
  attr,
  checked,
  options,
  readOnly = false,
  row,
  setChecked,
  type,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [edit, setEdit] = useState(false);
  const [isTarget, setIsTarget] = useState(false);
  const [value, setValue] = useState<string | number | undefined>(
    row[attr] as string | number
  );

  useEffect(() => {
    if (actions?.updateStatus === false && isTarget) {
      setIsTarget(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions?.updateStatus]);

  useEffect(() => {
    if (value !== String(row[attr])) {
      setValue(row[attr] as string);
      setEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row[attr]]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
      (type === 'text' || type === 'number') && inputRef.current.select();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;

    switch (key) {
      case 'Enter':
        handleSave();
        break;
      case 'Escape':
        setEdit(false);
        setValue(row[attr] as string);
        break;
      case 'Tab':
        handleSave();
        break;
      default:
        break;
    }
  };

  const handleSave = () => {
    if (actions) {
      setEdit(false);
      if (value !== row[attr]) {
        setIsTarget(true);
        actions.update(row.id, { [attr]: value });
      }
    }
  };

  const { id } = row;
  if (edit && attr !== 'id' && !readOnly) {
    if (type === 'select') {
      return (
        <td>
          <select
            id={id}
            className='bg-gray-300'
            onBlur={handleSave}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setValue(e.target.value)
            }
            name={attr}
            value={value}
          >
            {options &&
              options.map(({ name, value }) => (
                <option key={`${name}-${value}`} value={value}>
                  {name}
                </option>
              ))}
          </select>
        </td>
      );
    } else {
      return (
        <td>
          <input
            className='bg-gray-300'
            ref={inputRef}
            type={type}
            defaultValue={value}
            onBlur={handleSave}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setValue(
                type === 'number' ? Number(e.target.value) : e.target.value
              )
            }
            onKeyDown={handleKeyDown}
          />
        </td>
      );
    }
  } else if (actions?.updateStatus && isTarget) {
    return (
      <td>
        <div className='animate-pulse text-xs'>
          <span>Updating</span>
        </div>
      </td>
    );
  } else {
    return (
      <td onClick={() => setEdit(true)}>
        {attr === 'id' && actions ? (
          <MdDeleteForever
            className={`cursor-pointer text-red-700 h-5 w-5 ${
              actions.deleteStatus && checked[id] && 'animate-spin'
            }`}
            data-tip='Delete'
            onClick={() => {
              setChecked({ key: id, type: 'CHECK' });
              actions.delete(id);
            }}
          />
        ) : type === 'date' && row[attr] ? (
          displayDate(String(row[attr]))
        ) : (
          row[attr]
        )}
      </td>
    );
  }
};

export default DTCell;
