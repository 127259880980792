import { Meeting, TeamMember } from '../../../graphql/types';
import {
  namedOperations,
  useLogAttendeeMutation,
  useUnlogAttendeeMutation,
} from '../gql/_gen_/meeting.gql';

import { useSaveMeeting } from './use-save-meeting';

export const useToggleAttend = (meeting?: Meeting) => {
  const { handleSaveMeeting } = useSaveMeeting(meeting);
  const [logAttendee, { loading: logLoading }] = useLogAttendeeMutation({
    refetchQueries: [namedOperations.Query.MeetingTools],
  });
  const [unlogAttendee, { loading: unlogLoading }] = useUnlogAttendeeMutation({
    refetchQueries: [namedOperations.Query.MeetingTools],
  });

  const loading = logLoading || unlogLoading;

  const toggleAttend = async (item: TeamMember) => {
    if (meeting && meeting.id && meeting.attendees && item.id) {
      if (meeting.attendees.includes(item.id)) {
        await unlogAttendee({
          variables: {
            meetingId: meeting.id,
            attendeeId: item.id,
          },
        });
      } else {
        await logAttendee({
          variables: {
            meetingId: meeting.id,
            attendeeId: item.id,
          },
        });
      }
    } else if (!meeting?.id && item.id) {
      await handleSaveMeeting({
        attendees: [item.id],
      });
    }
  };

  return { loading, toggleAttend };
};
