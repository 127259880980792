import _ from 'lodash';

/**
 * Delete brand code from localStorage
 * @param prefix List of Strings. Prefix of the localStorage item.
 * @returns Returns true if the item is not found in the localStorage.
 */
export const delPrefix = async (prefix: string[]) => {
  prefix.forEach((p) => {
    _.forIn(localStorage, (_value: string, objKey: string) => {
      if (true === _.startsWith(objKey, p)) {
        localStorage.removeItem(objKey);
      }
    });
  });
};

/**
 * Delete key from localStorage
 * @param key String. Key of the localStorage item.
 * @returns Returns true if the item is not found in the localStorage.
 */
export const delValue = (key: string) => {
  localStorage.removeItem(key);
  return getValue(key) === null;
};

/**
 * Get value from localStorage
 * @param key String. Key of the localStorage item.
 * @returns Returns the current value associated with the given key,
 * or null if the given key does not exist.
 */
export const getValue = (key: string) => {
  return localStorage.getItem(key);
};

/**
 *
 * @param key String. Key of the localStorage item.
 * @param value String. Value of the localStorage item.
 * @returns Returns the newly stored/updated item from localStorage.
 */
export const saveValue = (key: string, value: string) => {
  localStorage.setItem(key, value);
  return getValue(key);
};
