import { IconType } from 'react-icons/lib';

type PopOutButtonProps = {
  icon: IconType;
  onClick: () => void;
  openModal: (v: boolean) => void;
};

const buttonClass =
  'transform transition-all relative border-2 opacity-20 text-4xl -left-4 hover:scale-125 hover:text-green-500 hover:translate-x-4 hover:opacity-100';

const handleClick = (props: PopOutButtonProps) => {
  const { onClick, openModal } = props;
  onClick();
  openModal(true);
};

export default function PopOutButton(props: PopOutButtonProps) {
  const { icon: Icon } = props;
  return (
    <div className='fixed left-0 top-80 z-20'>
      <button className={buttonClass} onClick={() => handleClick(props)}>
        <Icon />
      </button>
    </div>
  );
}
