import { ColumnBase } from '../../../types/Columns';
import _ from 'lodash';

export const colsForecasts: ColumnBase[] = [
  { key: 'forecast_sold_1' },
  { key: 'forecast_adr_1' },
  { key: 'forecast_revenue_1' },
  { key: 'forecast_sold_2' },
  { key: 'forecast_adr_2' },
  { key: 'forecast_revenue_2' },
];

export const colsGroup = (year: string | number): ColumnBase[] => [
  { key: 'group_adr', dataSource: 'rmData' },
  { key: 'group_adr_ly_final', dataSource: 'rmData' },
  {
    key: 'group_adr_ly_final_n',
    dataSource: 'rmData',
    header: `GADR LYF${year}`,
    tooltip: `Group ADR Final ${year} Years Ago`,
  },
  { key: 'group_revenue', dataSource: 'rmData' },
  { key: 'group_revenue_ly_final', dataSource: 'rmData' },
  {
    key: 'group_revenue_ly_final_n',
    dataSource: 'rmData',
    header: `GRev LYF${year}`,
    tooltip: `Group Revenue Final ${year} Years Ago`,
  },
  { key: 'group_sold_ly', dataSource: 'rmData' },
  { key: 'group_sold_ly_final', dataSource: 'rmData' },
  {
    key: 'group_sold_ly_n',
    dataSource: 'rmData',
    header: `GSold LY${year}`,
    tooltip: `Group Sold ${year} Years Ago`,
  },
  {
    key: 'group_sold_ly_final_n',
    dataSource: 'rmData',
    header: `GSold LYF${year}`,
    tooltip: `Group Sold Final ${year} Years Ago`,
  },
];

export const colsGtdCxl: ColumnBase[] = [{ key: 'policy_cd' }];

export const colsLiveData: ColumnBase[] = [
  { key: 'rt_transient_rooms_committed' },
  { key: 'rt_grp_rooms_committed' },
  { key: 'transient_delta_intraday' },
  { key: 'group_delta_intraday' },
];

export const colsPickup: ColumnBase[] = [
  { key: 'pu_1day' },
  { key: 'pu_2day' },
  { key: 'pu_3day' },
  { key: 'pu_4day' },
  { key: 'pu_5day' },
  { key: 'pu_6day' },
  { key: 'pu_7day' },
  { key: 'pu_14day' },
  { key: 'pu_30day' },
];

export const colsPickupDoa: ColumnBase[] = [
  { key: 'pu_dayofarr' },
  { key: 'pu_dayofarr_ly' },
];

export const colsRateLevelStatus: ColumnBase[] = [
  { key: 'lvl_a_status' },
  { key: 'lvl_b_status' },
  { key: 'lvl_c_status' },
  { key: 'lvl_d_status' },
  { key: 'lvl_e_status' },
  { key: 'lvl_f_status' },
  { key: 'lvl_g_status' },
  { key: 'lvl_h_status' },
];

export const colsStr: ColumnBase[] = [
  { key: 'compocc_py' },
  { key: 'compadr_py' },
  { key: 'comprevpar_py' },
];

export const colsTransient = (year: string | number): ColumnBase[] => [
  { key: 'transient_sold', dataSource: 'rmData' },
  { key: 'transient_sold_ly', dataSource: 'rmData' },
  { key: 'transient_sold_ly_final', dataSource: 'rmData' },
  {
    key: 'transient_sold_ly_n',
    dataSource: 'rmData',
    header: `Trn Sold LY${year}`,
    tooltip: `Transient Sold ${year} years Ago`,
  },
  {
    key: 'transient_sold_ly_final_n',
    dataSource: 'rmData',
    header: `Trn Sold LYF${year}`,
    tooltip: `Transient Sold Final ${year} years Ago`,
  },
  { key: 'transient_adr', dataSource: 'rmData' },
  { key: 'transient_revenue', dataSource: 'rmData' },
  { key: 'transient_l7_bkg_days', dataSource: 'rmData' },
];

export const colsWeather: ColumnBase[] = [{ key: 'weather.temperature' }];

export const varLyColumns: ColumnBase[] = [
  { key: 'var_sold_ly', dataSource: 'rmData' },
  { key: 'var_adr_ly', dataSource: 'rmData' },
  { key: 'var_revenue_ly', dataSource: 'rmData' },
  { key: 'current_rate_ly', dataSource: 'currentRateTable' },
];

export const varLyFColumns: ColumnBase[] = [
  { key: 'var_sold_ly_final', dataSource: 'rmData' },
  { key: 'var_adr_ly_final', dataSource: 'rmData' },
  { key: 'var_revenue_ly_final', dataSource: 'rmData' },
  { key: 'current_rate_ly_final', dataSource: 'currentRateTable' },
];

export const varLyNColumns = (year: string | number): ColumnBase[] => [
  {
    key: 'var_sold_ly_n',
    dataSource: 'rmData',
    header: `Δ LY${year}`,
    tooltip: `Variance to Sold ${year} Years Ago`,
  },
  {
    key: 'var_adr_ly_n',
    dataSource: 'rmData',
    header: `ΔA LY${year}`,
    tooltip: `Variance to ADR ${year} Years Ago`,
  },
  {
    key: 'var_revenue_ly_n',
    dataSource: 'rmData',
    header: `ΔREV LY${year}`,
    tooltip: `Variance to Revenue ${year} Years Ago`,
  },
  {
    key: 'current_rate_ly_n',
    dataSource: 'currentRateTable',
    header: `Rate LY${year}`,
    tooltip: `Current Rate ${year} Years Ago`,
  },
];

export const varLyFNColumns = (year: string | number): ColumnBase[] => [
  {
    key: 'var_sold_ly_final_n',
    dataSource: 'rmData',
    header: `Δ LYF${year}`,
    tooltip: `Variance to Sold LY Final ${year} Years Ago`,
  },
  {
    key: 'var_adr_ly_final_n',
    dataSource: 'rmData',
    header: `ΔA LYF${year}`,
    tooltip: `Variance to ADR LY Final ${year} Years Ago`,
  },
  {
    key: 'var_revenue_ly_final_n',
    dataSource: 'rmData',
    header: `ΔREV LYF${year}`,
    tooltip: `Variance to Revenue LY Final ${year} Years Ago`,
  },
  {
    key: 'current_rate_ly_final_n',
    dataSource: 'currentRateTable',
    header: `Rate LYF${year}`,
    tooltip: `Current Rate Final ${year} Years Ago`,
  },
];

export const tableOverview: ColumnBase[] = [
  { key: 'stay_date', dataSource: 'rmData' },
  { key: 'dow', dataSource: 'rmData' },
  { key: 'events_ty.count' },
  { key: 'events_ly.count' },
  { key: 'rooms_committed', dataSource: 'rmData' },
  { key: 'rt_rooms_committed' },
  { key: 'ooo', dataSource: 'rmData' },
  { key: 'remaining', dataSource: 'rmData' },
  { key: 'sold', dataSource: 'rmData' },
  { key: 'sold_ly', dataSource: 'rmData' },
  { key: 'sold_ly_final', dataSource: 'rmData' },
  { key: 'occ', dataSource: 'rmData' },
  { key: 'group_sold', dataSource: 'rmData' },
  { key: 'group_blocked', dataSource: 'rmData' },
  { key: 'delta_1day', dataSource: 'rmData' },
  { key: 'delta_3day', dataSource: 'rmData' },
  { key: 'delta_7day', dataSource: 'rmData' },
  { key: 'revenue', dataSource: 'rmData' },
  { key: 'revenue_ly', dataSource: 'rmData' },
  { key: 'revenue_ly_final', dataSource: 'rmData' },
  { key: 'adr', dataSource: 'rmData' },
  { key: 'adr_ly', dataSource: 'rmData' },
  { key: 'adr_ly_final', dataSource: 'rmData' },
  { key: 'current_rate' },
  { key: 'rm_recs.rate' },
  { key: 'rm_recs.status' },
  { key: 'rm_recs.notes' },
  { key: 'market_rate' },
  { key: 'recommended_rate' },
  { key: 'note_ty.content', dataSource: 'hotelNotes' },
  { key: 'note_ly.content', dataSource: 'hotelNotes' },
];

export const tableEvent: ColumnBase[] = [
  { key: 'name', dataSource: 'hotelEvents' },
  { key: 'start_date', dataSource: 'hotelEvents' },
  { key: 'end_date', dataSource: 'hotelEvents' },
  { key: 'details', dataSource: 'hotelEvents' },
];

export const simpleColumnMap: { [key: string]: ColumnBase[] } = {
  forecasts: colsForecasts,
  gtdCxl: colsGtdCxl,
  liveData: colsLiveData,
  pickup: colsPickup,
  pickupDoa: colsPickupDoa,
  rateLevelStatus: colsRateLevelStatus,
  str: colsStr,
  varLy: varLyColumns,
  varLyF: varLyFColumns,
  weather: colsWeather,
};

export const addColumns = (
  addCols: ColumnBase[],
  baseCols: ColumnBase[] = tableOverview,
  location?: {
    where?: 'after' | 'before' | 'index';
    position?: string | number;
  }
): ColumnBase[] => {
  const { where, position } = location || {};

  if (where) {
    switch (where) {
      case 'index':
        return baseCols
          .slice(0, Number(position))
          .concat(addCols, baseCols.slice(Number(position)));
      case 'after':
        const afterIndex = baseCols.findIndex((col) => col.key === position);
        if (afterIndex < 0) {
          return [...baseCols, ...addCols];
        }
        return baseCols
          .slice(0, afterIndex + 1)
          .concat(addCols, baseCols.slice(afterIndex + 1));
      case 'before':
        const beforeIndex = baseCols.findIndex((col) => col.key === position);
        if (beforeIndex < 0) {
          return [...baseCols, ...addCols];
        }
        return baseCols
          .slice(0, beforeIndex)
          .concat(addCols, baseCols.slice(beforeIndex));
      default:
        return [...baseCols, ...addCols];
    }
  } else {
    if (Array.isArray(addCols)) {
      return [...baseCols, ...addCols];
    } else {
      return baseCols;
    }
  }
};

export const removeColumns = (
  removeCols: ColumnBase[],
  baseCols: ColumnBase[] = tableOverview
): ColumnBase[] => {
  const targetKeys = removeCols?.map((col) => col.key);
  return _.filter(baseCols, (col) => !targetKeys?.includes(col.key));
};

export const removeHotelSpecificColumns = (
  baseCols: ColumnBase[] = tableOverview,
  target: 'all' | 'rates' | 'rooms' = 'all'
): ColumnBase[] => {
  let targetKeys: string[] = [];
  switch (target) {
    case 'all':
      targetKeys = ['client_rate', 'price_index', 'room_types.', 'rates.'];
      break;
    case 'rates':
      targetKeys = ['client_rate', 'price_index', 'rates.'];
      break;
    case 'rooms':
      targetKeys = ['room_types.'];
      break;
    default:
      targetKeys = ['client_rate', 'price_index', 'room_types.', 'rates.'];
      break;
  }

  return _.filter(baseCols, function (col) {
    return !targetKeys.some((tk) => col.key.includes(tk));
  });
};
