import { IconType } from 'react-icons/lib';
import { Placement } from '@popperjs/core';
import { Popover } from '@headlessui/react';
import ReactTooltip from 'react-tooltip';
import { usePopper } from 'react-popper';
import { useState } from 'react';

export type { Placement } from '@popperjs/core';

export type CloseFn = (
  focusableElement?:
    | HTMLElement
    | React.MutableRefObject<HTMLElement | null>
    | undefined
) => void;

type BtnPopoverProps = {
  btnClass: string;
  btnIcon: IconType;
  btnIconClass?: string;
  btnSpanClass: string;
  btnTooltip: string;
  offsetV?: number;
  offsetH?: number;
  panelContent: (props?: { close?: CloseFn }) => JSX.Element;
  popPlacement: Placement;
};

export default function BtnPopover(props: BtnPopoverProps) {
  const {
    btnClass,
    btnIcon: BtnIcon,
    btnIconClass = 'h-5 w-5',
    btnSpanClass,
    btnTooltip,
    offsetV = 0,
    offsetH = 0,
    panelContent: PanelContent,
    popPlacement,
  } = props;
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: popPlacement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [offsetV, offsetH],
        },
      },
    ],
  });

  return (
    <Popover>
      <Popover.Button
        className={btnClass}
        ref={setReferenceElement}
        data-tip={btnTooltip}
      >
        <span className={btnSpanClass} onClick={() => ReactTooltip.hide()}>
          <BtnIcon className={btnIconClass} aria-hidden='true' />
        </span>
      </Popover.Button>

      <Popover.Panel
        ref={setPopperElement}
        style={{ ...styles.popper, zIndex: 9999 }}
        {...attributes.popper}
      >
        {({ close }) => <PanelContent close={close} />}
      </Popover.Panel>
    </Popover>
  );
}
