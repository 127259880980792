/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LiveDataQueryVariables = Types.Exact<{
  brandCode?: Types.InputMaybe<Types.Scalars['String']>;
  startDate?: Types.InputMaybe<Types.Scalars['String']>;
  endDate?: Types.InputMaybe<Types.Scalars['String']>;
  snapshotDate?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type LiveDataQuery = { __typename?: 'Query', liveData?: Array<{ __typename?: 'LiveData', stay_date?: any | null, date_year?: string | null, date_ym?: string | null, date_yq?: string | null, date_yw?: string | null, group_delta_intraday?: number | null, rt_grp_rooms_committed?: number | null, rt_rooms_committed?: number | null, rt_transient_rooms_committed?: number | null, transient_delta_intraday?: number | null } | null> | null };


export const LiveDataDocument = gql`
    query LiveData($brandCode: String, $startDate: String, $endDate: String, $snapshotDate: String) {
  liveData(
    brandCode: $brandCode
    startDate: $startDate
    endDate: $endDate
    snapshotDate: $snapshotDate
  ) {
    stay_date
    date_year
    date_ym
    date_yq
    date_yw
    group_delta_intraday
    rt_grp_rooms_committed
    rt_rooms_committed
    rt_transient_rooms_committed
    transient_delta_intraday
  }
}
    `;

/**
 * __useLiveDataQuery__
 *
 * To run a query within a React component, call `useLiveDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveDataQuery({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      snapshotDate: // value for 'snapshotDate'
 *   },
 * });
 */
export function useLiveDataQuery(baseOptions?: Apollo.QueryHookOptions<LiveDataQuery, LiveDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiveDataQuery, LiveDataQueryVariables>(LiveDataDocument, options);
      }
export function useLiveDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiveDataQuery, LiveDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiveDataQuery, LiveDataQueryVariables>(LiveDataDocument, options);
        }
export type LiveDataQueryHookResult = ReturnType<typeof useLiveDataQuery>;
export type LiveDataLazyQueryHookResult = ReturnType<typeof useLiveDataLazyQuery>;
export type LiveDataQueryResult = Apollo.QueryResult<LiveDataQuery, LiveDataQueryVariables>;
export const namedOperations = {
  Query: {
    LiveData: 'LiveData'
  }
}