import * as AgendaItems from '../gql/_gen_/agenda.gql';
import * as Meetings from '../gql/_gen_/meeting.gql';

import { AgendaItem, Maybe } from '../../../graphql/types';

import { AgendaListItem } from './agenda-list-item';
import { ExpandedAgendaItem } from './expanded-agenda-item';
import { MeetingPanelsProps } from '../../reports/components/meeting-panels';
import Toggle from '../../../components/Toggle';
import { toast } from 'react-toastify';
import { useState } from 'react';

interface IAgendaView {
  meeting?: MeetingPanelsProps['meeting'];
  meetingTools?: MeetingPanelsProps['meetingTools'];
}

const AgendaView = ({ meeting, meetingTools }: IAgendaView) => {
  const [expanded, setExpanded] = useState<AgendaItem | null>();
  const [hideCovered, setHideCovered] = useState(false);

  const [removeAgendaItem] = AgendaItems.useRemoveAgendaItemMutation({
    refetchQueries: [Meetings.namedOperations.Query.MeetingTools],
  });

  const filterCovered = (item: Maybe<AgendaItem>) => {
    if (item && hideCovered && meeting?.agendaItems) {
      return !meeting.agendaItems.includes(item.id as string);
    }
    return true;
  };

  const handleDelete = async (itemId: string) => {
    await removeAgendaItem({
      variables: {
        removeAgendaItemId: itemId,
      },
    });
    toast.success('Agenda item deleted', { position: 'top-left' });
  };

  const getItemNotes = (itemId: string | undefined | null) => {
    if (meeting?.agendaItemNotes && itemId) {
      return meeting.agendaItemNotes.find((note) => note?.itemId === itemId)
        ?.notes;
    }
  };

  return (
    <section className='px-3'>
      <div className='overflow-auto mt-3'>
        {expanded ? (
          <ExpandedAgendaItem
            expanded={expanded}
            handleDelete={handleDelete}
            setExpanded={setExpanded}
            meetingId={meeting?.id}
            itemNotes={getItemNotes(expanded?.id) as string}
          />
        ) : (
          <div className='flex flex-col'>
            <div className='bg-white w-full'>
              <Toggle
                label={
                  hideCovered ? 'Show Reviewed Items' : 'Hide Reviewed Items'
                }
                checked={hideCovered}
                onChange={() => setHideCovered(!hideCovered)}
              />
            </div>
            <ul className='divide-y divide-gray-200 mt-6 pr-2'>
              {meetingTools?.agendaItems &&
                meetingTools?.agendaItems
                  .filter(filterCovered)
                  .map((item) => (
                    <AgendaListItem
                      data={item}
                      handleDelete={handleDelete}
                      key={item?.id}
                      meeting={meeting}
                      setExpanded={setExpanded}
                      itemNotes={getItemNotes(item?.id) as string}
                    />
                  ))}
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export { AgendaView };
