import Highcharts from 'highcharts/highstock';
import { RmData } from '../graphql/types';
import { displayDate } from '../helpers/dateHelpers';
import { masterColumns } from '../renderers/masterColumns';

const defaultMetrics = ['sold', 'remaining', 'ooo'];

export function tonightDonut(
  data: RmData,
  metrics = defaultMetrics
): Highcharts.Options {
  let options = {
    chart: {
      margin: [0, 0, 0, 0],
      plotShadow: false,
      spacing: [0, 0, 0, 0],
      type: 'pie',
    } as Highcharts.ChartOptions,
    exporting: {
      enabled: false,
    } as Highcharts.ExportingOptions,
    title: {
      floating: true,
      text: `${data.sold}<br>Sold`,
      verticalAlign: 'middle',
    } as Highcharts.TitleOptions,
    plotOptions: {
      pie: {
        alignTo: 'plotEdges',
        allowPointSelect: false,
        cursor: 'pointer',
        dataLabels: {
          crop: false,
          distance: 10,
          enabled: true,
          format: '<b>{point.header}</b>: {point.percentage:.1f}%',
          style: {
            fontSize: '10px',
          },
        },
        size: '95%',
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y} Rooms</b>',
        },
      },
    } as Highcharts.PlotOptions,
    series: [
      {
        name: displayDate(data.stay_date),
        colorByPoint: true,
        innerSize: '40%',
        data: createSeriesData(),
        type: 'pie',
      } as Highcharts.SeriesPieOptions,
    ],
  };

  function createSeriesData(): Highcharts.PointOptionsObject[] {
    return metrics.map((metric) => {
      let yValue = data[metric as keyof RmData];

      if (yValue === null || yValue === undefined) {
        yValue = 0;
      }

      return {
        name: masterColumns[metric].tooltip,
        header: masterColumns[metric].header,
        y: yValue,
      };
    });
  }

  return options;
}
