import { BsDash, BsDashLg } from 'react-icons/bs';
import {
  FaBan,
  FaCalendarTimes,
  FaCalendarWeek,
  FaConciergeBell,
  FaDotCircle,
  FaHandPaper,
  FaStoreSlash,
} from 'react-icons/fa';

import { CellObject } from '../features/overview-table/hooks/use-table';
import { IconType } from 'react-icons/lib';
import ReactDOMServer from 'react-dom/server';

type RendererProps = {
  cell: CellObject;
};

type IconProps = {
  icon: IconType;
  value: string;
  cls: string;
  formattedValue: string;
};

const getIconProps = (
  value: string,
  formattedValue: string | null
): IconProps | undefined => {
  if (formattedValue && formattedValue !== null) {
    if (value && typeof value === 'string') {
      if (value.includes('OPEN')) {
        return {
          icon: FaConciergeBell,
          value,
          cls: 'text-green-500',
          formattedValue,
        };
      } else if (value.includes('CLOSE') && !value.includes('SOFT')) {
        return { icon: FaBan, value, cls: 'text-red-500', formattedValue };
      } else if (value.includes('SOLD_OUT')) {
        return {
          icon: FaStoreSlash,
          value,
          cls: 'text-yellow-600',
          formattedValue,
        };
      } else if (value.includes('CTA')) {
        return {
          icon: FaHandPaper,
          value,
          cls: 'text-red-300',
          formattedValue,
        };
      } else if (value.includes('N/A')) {
        return { icon: BsDashLg, value, cls: 'text-grey-600', formattedValue };
      } else if (value.includes('ZERO') && !value.includes('N/A')) {
        return {
          icon: FaDotCircle,
          value,
          cls: 'text-yellow-300',
          formattedValue,
        };
      } else if (value.includes('MLOS')) {
        let los = parseInt(value.split('-')[1]).toString();
        value = 'MLOS ' + los;
        return {
          icon: FaCalendarWeek,
          value,
          cls: 'text-blue-500',
          formattedValue,
        };
      } else if (value.includes('SOFT')) {
        value = 'SOFT CLOSE';
        return {
          icon: FaCalendarTimes,
          value,
          cls: 'text-indigo-500',
          formattedValue,
        };
      }
    }
    return { icon: BsDash, value, cls: '', formattedValue };
  }
};

const StatusTooltip = (props: { formattedValue: string }): JSX.Element => {
  const [status, sold, remaining] = props.formattedValue.split('/');
  return (
    <div className='flex flex-col items-center'>
      <div>Status: {status}</div>
      <div>Sold: {sold}</div>
      <div>Remaining: {remaining}</div>
    </div>
  );
};

const StatusIcon = (props: IconProps) => {
  const { icon: Icon, formattedValue } = props;

  return (
    <>
      <span
        className={props.cls}
        data-html={true}
        data-tip={ReactDOMServer.renderToString(
          <StatusTooltip formattedValue={formattedValue} />
        )}
      >
        <Icon />
      </span>
    </>
  );
};

export default function RenderRateLevelStatus({ cell }: RendererProps) {
  if (cell.value !== undefined) {
    let value = cell.value as string;
    const formattedValue = cell.displayValue;
    const iconProps = getIconProps(value, formattedValue);

    if (cell !== null && typeof cell === 'object') {
      value = String(cell.value);
    }

    if (iconProps) {
      return <StatusIcon {...iconProps} />;
    }
    return <span>{value}</span>;
  } else {
    return <span></span>;
  }
}
