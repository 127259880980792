import { State as HotelState, useHotel } from '../context/hotelContext';
import { cityState, logo } from '../helpers/hotelHelper';
import { getValue, saveValue } from '../helpers/localStorageHelper';
import {
  useIsReport,
  useIsShared,
} from '../features/reports/hooks/use-report-location';

import Editable from '../components/Editable';
import { Meeting } from '../graphql/types';
import MeetingCategory from '../features/reports/components/meeting-category';
import { clsx } from 'clsx';
import { useAppTrack } from '../context/appTrackContext';
import { useState } from 'react';

type Props = {
  hotel?: HotelState;
  meeting?: Meeting;
  page: string;
  toolbar?: JSX.Element;
};

const PropertyHeader = ({
  hotel: presetHotel,
  meeting,
  page,
  toolbar,
}: Props) => {
  const { isShared } = useIsShared();
  const { isReport } = useIsReport();
  const KEY_SHOW_PROPERTY = `show_property_${
    page.split('/')[1] || page.split('/')[0]
  }`;
  const shouldShowProperty =
    getValue(KEY_SHOW_PROPERTY) === null
      ? true
      : getValue(KEY_SHOW_PROPERTY) === 'true';
  const [showProperty, setShowProperty] = useState(shouldShowProperty);

  const { hotel, loading, setBrandCode } = useHotel();
  const { track } = useAppTrack();

  const handleBrandCodeChange = (brandCode: string) => {
    setBrandCode(brandCode);
    track({ action: 'brand_code_change', brandCode, page });
  };

  const handleToggleShowProperty = () => {
    saveValue(KEY_SHOW_PROPERTY, String(!showProperty));
    setShowProperty(!showProperty);
  };

  const BrandCode = () => {
    return (
      <span className='font-bold ml-2'>
        {hotel?.brand_code || presetHotel?.brand_code}
      </span>
    );
  };

  return (
    <div className='flex w-full justify-between items-center'>
      <div className='sm:flex-col sm:items-center sm:justify-between mt-2'>
        <div className='flex flex-row'>
          {/* Property Details */}
          <div className='flex space-x-5'>
            <div
              className={
                isShared ? 'flex-shrink-0' : 'flex-shrink-0 cursor-pointer'
              }
              onClick={() => (isShared ? null : handleToggleShowProperty())}
            >
              {showProperty ? (
                <img
                  className='mx-auto h-20 rounded-sm'
                  src={hotel ? logo(hotel) : logo(presetHotel)}
                  alt=''
                />
              ) : (
                <BrandCode />
              )}
            </div>
            <div
              className={clsx(
                !showProperty && 'hidden',
                'mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left'
              )}
            >
              <Editable
                disabled={isShared || isReport}
                fallback='Enter Brand Code'
                initialValue={hotel?.brand_code || presetHotel?.brand_code}
                inputTextSize='text-sm'
                inputWidth='w-16'
                onChange={handleBrandCodeChange}
              />
              <p className='text-xl font-bold text-gray-900 sm:text-2xl'>
                {loading || (!hotel && !presetHotel)
                  ? 'Fetching hotel...'
                  : hotel?.name || presetHotel?.name}
              </p>
              <p className='text-sm font-medium text-gray-600'>
                {loading || (!hotel && !presetHotel)
                  ? ''
                  : cityState(hotel) || cityState(presetHotel)}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-2 flex justify-center sm:mt-0'>{toolbar}</div>
      </div>
      {page === 'reports' ? <MeetingCategory meeting={meeting} /> : null}
    </div>
  );
};

export default PropertyHeader;
