import { Actions, DataRow, FormField } from '../../types/DataTable';
import {
  State as Checked,
  Action as CheckedActions,
} from '../../reducers/useBulkCheckboxes';

import DTCell from './DTCell';

type Props = {
  actions?: Actions;
  checked: Checked;
  fields: FormField[];
  idx: number;
  row: DataRow;
  setChecked: React.Dispatch<CheckedActions>;
};

const DTRow = ({ actions, checked, fields, idx, row, setChecked }: Props) => {
  const { id } = row;

  const cells = fields.map(({ name, options, readOnly, type }, i) => (
    <DTCell
      key={`row-${idx}-cell-${i}`}
      actions={actions}
      attr={name}
      checked={checked}
      options={options}
      readOnly={readOnly}
      row={row}
      setChecked={setChecked}
      type={type}
    />
  ));

  return (
    <tr className='text-xs bg-gray-50'>
      <td className='flex items-center py-5 px-6 font-medium'>
        <input
          checked={checked[id] || false}
          readOnly={true}
          className='mr-3'
          type='checkbox'
          name={id}
          id={id}
          onClick={() => {
            setChecked({ key: id, type: 'TOGGLE_CHECK' });
          }}
        />
      </td>
      {cells}
    </tr>
  );
};

export default DTRow;
