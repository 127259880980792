import Paginate from './Paginate';
import { useState } from 'react';

type TableProps = {
  headers: any[];
  rows: any[][];
  perPage?: number;
};

export default function Table(props: TableProps) {
  const perPage = props.perPage || 9;
  const { headers, rows } = props;
  const totalPages = Math.ceil(rows.length / perPage);
  const [pageRange, setPageRange] = useState([0, totalPages]);

  const next = () => {
    if (pageRange[0] + 1 <= totalPages) {
      const last =
        pageRange[0] + 1 > totalPages ? totalPages : pageRange[0] + 1;
      setPageRange([last, pageRange[1]]);
    }
  };

  const previous = () => {
    if (pageRange[0] - 1 >= 0) {
      const first = pageRange[0] - 1 < 0 ? 0 : pageRange[0] - 1;
      setPageRange([first, pageRange[1]]);
    }
  };

  return (
    <div className='flex flex-col text-center'>
      <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
          <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-b-lg'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  {headers.map((header, i) => {
                    return (
                      <th
                        scope='col'
                        key={`header-${i}`}
                        className='px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider'
                      >
                        {header}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {rows
                  .slice(
                    pageRange[0] * perPage,
                    pageRange[0] * perPage + perPage
                  )
                  .map((row, r) => (
                    <tr key={`row-${r}`}>
                      {row.map((cell, c) => {
                        let cellValue = cell;
                        let extraClasses = 'text-gray-900';
                        if (typeof cell === 'object' && cell !== null) {
                          cellValue = cell.value;
                          cell.extraClasses &&
                            (extraClasses = cell.extraClasses);
                        }
                        return (
                          <td
                            key={`cell-${r}-${c}`}
                            className={`px-6 py-3 whitespace-nowrap text-sm font-medium ${extraClasses}`}
                          >
                            {cellValue}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
              </tbody>
            </table>
            {perPage && totalPages && totalPages > 1 ? (
              <Paginate
                first={pageRange[0]}
                last={pageRange[1]}
                total={rows.length}
                next={next}
                previous={previous}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
