import { endOfYear, firstOfYear, getYear } from '../../helpers/dateHelpers';
import {
  useCreateMonthlyBudgetMutation,
  useDeleteMonthlyBudgetMutation,
  useHotelMonthlyBudgetsQuery,
  useUpdateMonthlyBudgetMutation,
} from '../../features/budget/_gen_/budget.gql';
import { useEffect, useState } from 'react';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { CurrencyCell } from '../../components/TableCells';
import DataTableContainer from '../DataTableContainer';
import Editable from '../../components/Editable';
import { MdDeleteForever } from 'react-icons/md';
import { MonthlyBudget } from '../../graphql/types';
import ReactTooltip from 'react-tooltip';
import { getYearMonths } from '../../helpers/dateHelpers';
import numbro from 'numbro';
import { useHotel } from '../../context/hotelContext';
import { useUser } from '../../context/userContext';

type MonthRowProps = {
  data?: MonthlyBudget | null;
  month: {
    display: string;
    value: number;
  };
  userId: string;
  hotelId: number;
  selected: boolean;
  activeCell?: { row: number; col: number };
  handleSelect: (month: string) => void;
  handleDelete: (id: Array<string>) => void;
  setActiveCell: (activeCell: { row: number; col: number }) => void;
};

function MonthRow({
  data,
  month,
  hotelId,
  userId,
  selected,
  activeCell,
  handleSelect,
  handleDelete,
  setActiveCell,
}: MonthRowProps) {
  const {
    budget_sold: sold,
    budget_adr: adr,
    budget_revenue: revenue,
  } = data || {};
  const year = getYear();
  const { value, display } = month;

  const [createMonthlyBudget] = useCreateMonthlyBudgetMutation();
  const [updateMonthlyBudget] = useUpdateMonthlyBudgetMutation();

  const handleADRChange = (value: string) => {
    if (data?.id) {
      updateMonthlyBudget({
        variables: {
          updateMonthlyBudgetId: data?.id || '',
          monthlyBudget: {
            budget_adr: parseFloat(value),
          },
        },
        refetchQueries: ['HotelMonthlyBudgets'],
      });
    } else {
      createMonthlyBudget({
        variables: {
          monthlyBudget: {
            hotel_id: hotelId,
            created_by_id: userId,
            stay_date: `${year}-${month.value}-01`,
            budget_adr: parseFloat(value),
          },
        },
        refetchQueries: ['HotelMonthlyBudgets'],
      });
    }

    if (activeCell === undefined) {
      setActiveCell({ row: month.value, col: 2 });
    }
  };

  const handleSoldChange = (value: string) => {
    if (data?.id) {
      updateMonthlyBudget({
        variables: {
          updateMonthlyBudgetId: data?.id || '',
          monthlyBudget: {
            budget_sold: parseFloat(value),
          },
        },
        refetchQueries: ['HotelMonthlyBudgets'],
      });
    } else {
      createMonthlyBudget({
        variables: {
          monthlyBudget: {
            hotel_id: hotelId,
            created_by_id: userId,
            stay_date: `${year}-${month.value}-01`,
            budget_sold: parseFloat(value),
          },
        },
        refetchQueries: ['HotelMonthlyBudgets'],
      });
    }

    if (activeCell === undefined) {
      setActiveCell({ row: month.value, col: 1 });
    }
  };

  const deleteMonth = () => {
    if (data?.id) {
      handleDelete([data?.id]);
    }
  };

  return (
    <tr className='border-b border-gray-200 bg-gray-50 h-10'>
      <td className='w-24'>
        <div className='flex items-center ml-10'>
          {data && (
            <input
              type='checkbox'
              value={value}
              checked={selected}
              onChange={() => handleSelect(data.id)}
            />
          )}
          {selected && (
            <button
              className='ml-2 text-xl text-red-700'
              onClick={() => deleteMonth()}
            >
              <MdDeleteForever />
            </button>
          )}
        </div>
      </td>
      <td className='text-center py-2 font-medium text-gray-600'>{display}</td>
      <td className='text-center py-2 w-28'>
        <Editable
          initialValue={sold ? String(sold) : ''}
          inputTextSize='text-md'
          inputWidth='w-14'
          onChange={handleSoldChange}
          activeCell={activeCell}
          setActiveCell={setActiveCell}
          row={month.value}
          col={1}
        />
      </td>
      <td className='text-center py-2 w-28'>
        <Editable
          initialValue={
            adr
              ? numbro(adr).formatCurrency({
                  mantissa: 2,
                  thousandSeparated: true,
                })
              : ''
          }
          inputTextSize='text-md'
          inputWidth='w-14'
          onChange={handleADRChange}
          activeCell={activeCell}
          setActiveCell={setActiveCell}
          row={month.value}
          col={2}
        />
      </td>
      <CurrencyCell
        value={revenue || (adr && sold ? adr * sold : '')}
        metric='revenue'
        style={['text-center py-2 text-blue-900 font-semibold']}
      />
    </tr>
  );
}

function Budget() {
  const [selected, setSelected] = useState<string[]>([]);
  const [activeCell, setActiveCell] = useState<
    { row: number; col: number } | undefined
  >(undefined);
  const { hotel } = useHotel();
  const { user } = useUser();
  const months = getYearMonths();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [selected]);

  const { data: budgetData, loading: loadingBudget } =
    useHotelMonthlyBudgetsQuery({
      skip: !hotel?.hotel_id || !hotel?.brand_code,
      variables: {
        filters: {
          hotelId: hotel?.hotel_id || hotel?.brand_code,
          startDate: firstOfYear(),
          endDate: endOfYear(),
        },
      },
    });

  const [deleteMonthlyBudget] = useDeleteMonthlyBudgetMutation();

  const getMonthData = (month: number) => {
    const monthData = budgetData?.hotelMonthlyBudgets?.find(
      (data) => data?.month === month
    );
    return monthData;
  };

  const handleRowSelect = (month: string) => {
    if (selected.includes(month)) {
      setSelected(selected.filter((m) => m !== month));
    } else {
      setSelected([...selected, month]);
    }
  };

  const handleSelectAllRows = () => {
    if (
      budgetData?.hotelMonthlyBudgets &&
      selected.length < budgetData?.hotelMonthlyBudgets?.length
    ) {
      const allMonths = budgetData.hotelMonthlyBudgets.map((data) => {
        return data?.id || '';
      });
      return setSelected(allMonths);
    }

    setSelected([]);
  };

  const handleDelete = (ids: Array<string>) => {
    ids.forEach((id) => {
      deleteMonthlyBudget({
        variables: {
          deleteMonthlyBudgetId: id,
        },
        refetchQueries: ['HotelMonthlyBudgets'],
      });
    });

    setSelected([]);
  };

  const handleSetActiveCell = (activeCell: { row: number; col: number }) => {
    setActiveCell(activeCell);
  };

  return (
    <div className='border-t border-gray-200 mt-6'>
      {loadingBudget ? (
        <div className='flex justify-center items-center h-64'>
          <AiOutlineLoading3Quarters className='animate-spin ml-2 text-blue-900 w-10 h-10' />
          <h2 className='ml-4 text-2xl'>Loading Budget...</h2>
        </div>
      ) : (
        <DataTableContainer>
          <table className='w-[70%] mx-auto shadow rounded mt-4'>
            <thead>
              <tr className='border-b border-gray-200'>
                <th className='w-24'>
                  <div className='flex items-center ml-10'>
                    <input
                      type='checkbox'
                      onChange={handleSelectAllRows}
                      checked={
                        selected.length > 0 &&
                        selected.length ===
                          budgetData?.hotelMonthlyBudgets?.length
                      }
                    />
                    {selected.length > 1 && (
                      <button
                        className='ml-2 text-xl text-red-700'
                        onClick={() => handleDelete(selected)}
                        data-tip='Delete Selected'
                      >
                        <MdDeleteForever />
                      </button>
                    )}
                  </div>
                </th>
                <th className='py-2 text-gray-800'>Month</th>
                <th className='py-2 text-gray-800'>Sold</th>
                <th className='py-2 text-gray-800'>ADR</th>
                <th className='py-2 text-gray-800'>Revenue</th>
              </tr>
            </thead>
            <tbody>
              {months.map((month) => {
                const data = getMonthData(month.value);
                const rowSelected = data?.id
                  ? selected.includes(data?.id)
                  : false;
                return (
                  <MonthRow
                    key={month.value}
                    month={month}
                    data={getMonthData(month.value)}
                    userId={user?.id || ''}
                    hotelId={hotel?.hotel_id || hotel?.brand_code}
                    handleSelect={handleRowSelect}
                    selected={rowSelected}
                    handleDelete={handleDelete}
                    setActiveCell={handleSetActiveCell}
                    activeCell={activeCell}
                  />
                );
              })}
            </tbody>
          </table>
        </DataTableContainer>
      )}
      <ReactTooltip />
    </div>
  );
}

export default Budget;
