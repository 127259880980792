import {
  ColumnSettings,
  HotelSettings,
  TableAction,
} from '../../../features/table-settings';

import FieldSelect from '../../../components/FieldSelect';
import { HeaderObject } from '../../../features/overview-table/hooks/use-table';
import { HookFuncs } from '../../../features/overview-table/hooks/use-data-hooks';
import InsertLocation from '../../../components/data_grid/InsertLocation';
import RateParams from '../../../components/RateParams';
import RateUploadBtn from '../../../components/RateUploadBtn';
import SectionSwitches from '../../../components/data_grid/SectionSwitches';
import { ToggleSwitchProps } from '../../../types/DataGrid';
import { useDashboard } from '../../../context/dashboardContext';
import { useTracking } from 'react-tracking';

type Props = {
  headers: HeaderObject[];
  hooks: HookFuncs;
};

const SideButtons = (props: Props) => {
  const { headers, hooks } = props;
  const {
    brandCode,
    hotelSettings,
    options,
    rateParams,
    setRateParams,
    setTableSettings,
  } = useDashboard();
  const { trackEvent } = useTracking();
  const { yearsCompare } = options;

  const { forecasts, liveData, rmData, rateShops, rooms } = hooks;

  const colLockOptions = headers.map((h, i) => {
    return { value: String(i + 1), label: h.title };
  });

  const lockDropDown = () => {
    return (
      <FieldSelect
        field='colLock'
        onChange={(newColLock: string) => {
          setTableSettings({
            brandCode,

            type: 'COL_LOCK',
            key: 'colLock',
            payload: { colLock: Number(newColLock) },
          });
        }}
        options={colLockOptions}
        value={hotelSettings?.colLock || 4}
      />
    );
  };

  const togExt = (key: string) => {
    const settings =
      hotelSettings &&
      (hotelSettings[key as keyof HotelSettings] as ColumnSettings);
    if (settings && hotelSettings) {
      const props = {
        disabled: settings.visible,
        field: `location-${key}`,
        onChange: (position: string) => {
          setTableSettings({
            brandCode,
            type: 'LOCATION',
            key,
            payload: { location: { ...settings.location, position } },
          });
        },
        value: settings?.location.position as string,
      };
      if (props) {
        if (key === 'rates') {
          return (
            <RateParams
              params={rateParams}
              onChange={setRateParams}
              rateShops={rateShops}
              insertProps={{ ...props, tableCols: hotelSettings.tableCols }}
            />
          );
        } else {
          return (
            <InsertLocation {...props} tableCols={hotelSettings.tableCols} />
          );
        }
      }
    }
  };

  const trackToggleButton = (action: TableAction) => {
    trackEvent({ action: `toggle_button_${action.key}_${action.payload}` });
    setTableSettings(action);
  };

  const togglesList = (): ToggleSwitchProps[] => {
    if (hotelSettings) {
      return [
        {
          label: 'Reset Settings',
          enabled: true,
          setEnabled: () =>
            trackToggleButton({
              brandCode,

              type: 'RESET_SETTINGS',
              key: 'reset_settings',
            }),
        },
        {
          label: 'Var LY',
          enabled: hotelSettings.varLy,
          loading: rmData.loading,
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,

              type: 'TOGGLE',
              key: 'varLy',
              payload: { visible: value, year: yearsCompare },
            }),
        },
        {
          label: 'Var LYF',
          enabled: hotelSettings.varLyF,
          loading: rmData.loading,
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,

              type: 'TOGGLE',
              key: 'varLyF',
              payload: { visible: value, year: yearsCompare },
            }),
        },
        {
          label: `Var LY${yearsCompare}`,
          enabled: hotelSettings.varLyN,
          loading: rmData.loading,
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,

              type: 'TOGGLE',
              key: 'varLyN',
              payload: { visible: value, year: yearsCompare },
            }),
        },
        {
          label: `Var LYF${yearsCompare}`,
          enabled: hotelSettings.varLyFN,
          loading: rmData.loading,
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,

              type: 'TOGGLE',
              key: 'varLyFN',
              payload: { visible: value, year: yearsCompare },
            }),
        },
        {
          label: 'Forecasts',
          enabled: hotelSettings.forecasts.visible,
          extension: togExt('forecasts'),
          loading: forecasts.loading,
          superscript: hotelSettings.forecasts.showSuperscript,
          toolbar: hotelSettings.forecasts.toolbar,
          setSuperscript: (value) => {
            trackToggleButton({
              brandCode,

              type: 'SUPERSCRIPT',
              key: 'forecasts',
              payload: { showSuperscript: value },
            });
          },
          setToolbar: (value) => {
            trackToggleButton({
              brandCode,

              type: 'TOOLBAR',
              key: 'forecasts',
              payload: { toolbar: value },
            });
          },
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,

              type: 'TOGGLE',
              key: 'forecasts',
              payload: { visible: value, year: yearsCompare },
            }),
        },
        {
          label: 'Group',
          enabled: hotelSettings.group.visible,
          extension: togExt('group'),
          toolbar: hotelSettings.group.toolbar,
          setToolbar: (value) => {
            trackToggleButton({
              brandCode,

              type: 'TOOLBAR',
              key: 'group',
              payload: { toolbar: value },
            });
          },
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,

              type: 'TOGGLE',
              key: 'group',
              payload: { visible: value, year: yearsCompare },
            }),
        },
        {
          label: 'GTD/CXL',
          enabled: hotelSettings.gtdCxl.visible,
          extension: togExt('gtdCxl'),
          toolbar: hotelSettings.gtdCxl.toolbar,
          setToolbar: (value) => {
            trackToggleButton({
              brandCode,

              type: 'TOOLBAR',
              key: 'gtdCxl',
              payload: { toolbar: value },
            });
          },
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,

              type: 'TOGGLE',
              key: 'gtdCxl',
              payload: { visible: value, year: yearsCompare },
            }),
        },
        {
          label: 'Live Data',
          enabled: hotelSettings.liveData.visible,
          extension: togExt('liveData'),
          loading: liveData.loading,
          toolbar: hotelSettings.liveData.toolbar,
          setToolbar: (value) => {
            trackToggleButton({
              brandCode,

              type: 'TOOLBAR',
              key: 'liveData',
              payload: { toolbar: value },
            });
          },
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,

              type: 'TOGGLE',
              key: 'liveData',
              payload: { visible: value, year: yearsCompare },
            }),
        },
        {
          label: 'Pickup',
          enabled: hotelSettings.pickup.visible,
          extension: togExt('pickup'),
          toolbar: hotelSettings.pickup.toolbar,
          setToolbar: (value) => {
            trackToggleButton({
              brandCode,

              type: 'TOOLBAR',
              key: 'pickup',
              payload: { toolbar: value },
            });
          },
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,

              type: 'TOGGLE',
              key: 'pickup',
              payload: { visible: value, year: yearsCompare },
            }),
        },
        {
          label: 'Pickup Day of Arrival',
          enabled: hotelSettings.pickupDoa.visible,
          extension: togExt('pickupDoa'),
          toolbar: hotelSettings.pickupDoa.toolbar,
          setToolbar: (value) => {
            trackToggleButton({
              brandCode,

              type: 'TOOLBAR',
              key: 'pickupDoa',
              payload: { toolbar: value },
            });
          },
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,

              type: 'TOGGLE',
              key: 'pickupDoa',
              payload: { visible: value, year: yearsCompare },
            }),
        },
        {
          label: 'Rates (API)',
          enabled: hotelSettings.rates.visible,
          extension: togExt('rates'),
          loading: rateShops.loading,
          popVertOffset: 100,
          toolbar: hotelSettings.rates.toolbar,
          setToolbar: (value) => {
            trackToggleButton({
              brandCode,

              type: 'TOOLBAR',
              key: 'rates',
              payload: { toolbar: value },
            });
          },
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,

              type: 'TOGGLE',
              key: 'rates',
              hotelCols: rateShops?.data?.compSet,
              payload: { visible: value, year: yearsCompare },
            }),
        },
        {
          label: 'Rate Level Status',
          enabled: hotelSettings.rateLevelStatus.visible,
          extension: togExt('rateLevelStatus'),
          toolbar: hotelSettings.rateLevelStatus.toolbar,
          setToolbar: (value) => {
            trackToggleButton({
              brandCode,

              type: 'TOOLBAR',
              key: 'rateLevelStatus',
              payload: { toolbar: value },
            });
          },
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,

              type: 'TOGGLE',
              key: 'rateLevelStatus',
              payload: { visible: value, year: yearsCompare },
            }),
        },
        {
          label: 'Roomtypes',
          enabled: hotelSettings.rooms.visible,
          extension: togExt('rooms'),
          loading: rooms.loading,
          toolbar: hotelSettings.rooms.toolbar,
          setToolbar: (value) => {
            trackToggleButton({
              brandCode,

              type: 'TOOLBAR',
              key: 'rooms',
              payload: { toolbar: value },
            });
          },
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,

              type: 'TOGGLE',
              key: 'rooms',
              payload: { visible: value, year: yearsCompare },
            }),
        },
        {
          label: 'STR',
          enabled: hotelSettings.str.visible,
          extension: togExt('str'),
          toolbar: hotelSettings.str.toolbar,
          setToolbar: (value) => {
            trackToggleButton({
              brandCode,

              type: 'TOOLBAR',
              key: 'str',
              payload: { toolbar: value },
            });
          },
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,

              type: 'TOGGLE',
              key: 'str',
              payload: { visible: value, year: yearsCompare },
            }),
        },
        {
          label: 'Transient',
          enabled: hotelSettings.transient.visible,
          extension: togExt('transient'),
          toolbar: hotelSettings.transient.toolbar,
          setToolbar: (value) => {
            trackToggleButton({
              brandCode,

              type: 'TOOLBAR',
              key: 'transient',
              payload: { toolbar: value },
            });
          },
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,
              type: 'TOGGLE',
              key: 'transient',
              payload: { visible: value, year: yearsCompare },
            }),
        },
        {
          label: 'Use Cache',
          enabled: hotelSettings.useCache || false,
          loading: rmData.loading,
          setEnabled: (value) =>
            trackToggleButton({
              brandCode,
              type: 'CACHE',
              key: 'cache',
              payload: { useCache: value, year: yearsCompare },
            }),
        },
      ];
    }
    return [];
  };

  return (
    <>
      <SectionSwitches
        togglesList={togglesList()}
        lockDropDown={lockDropDown()}
      />
      <RateUploadBtn />
    </>
  );
};

export default SideButtons;
