import { LoginCallback, SecureRoute } from '@okta/okta-react';
import { Route, Switch } from 'react-router-dom';

import { AdminDashboard } from './features/admin/admin-dashboard';
import Changelog from './Changelog';
import Dashboard from './dashboards/Dashboard';
import { LiveReport } from './features/reports/live-report';
import Portfolio from './features/portfolio/portfolio';
import { ReportDataProvider } from './features/reports/context/report-data-context';
import { SharedReport } from './features/reports/shared-report';
import { UserProvider } from './context/userContext';
import Welcome from './Welcome';

export const SHARED_PATH = '/shared_report/';
export const REPORT_PATH = '/report/';

function AppRoutes() {
  return (
    <Switch>
      <Route path='/login/callback' component={LoginCallback} exact />
      <Route path='/' component={Welcome} exact />
      <SecureRoute path='/changelog' component={Changelog} exact />
      <SecureRoute path='/admin' component={AdminDashboard} />
      <SecureRoute path='/dashboard' component={Dashboard} />
      <SecureRoute path='/portfolio' exact>
        <UserProvider>
          <Portfolio />
        </UserProvider>
      </SecureRoute>
      <ReportDataProvider>
        <Route
          path={`${SHARED_PATH}:meetingId`}
          component={SharedReport}
          exact
        />
        <SecureRoute
          path={`${REPORT_PATH}:meetingId`}
          component={LiveReport}
          exact
        />
        <SecureRoute path='/reports' component={LiveReport} exact />
      </ReportDataProvider>
    </Switch>
  );
}

export default AppRoutes;
