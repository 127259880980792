import { Actions, DataRow, FormField } from '../../../types/DataTable';
import {
  Event,
  EventTableRows,
  PaginatedEventsObject,
} from '../../../types/Event';
import {
  SortDir,
  TableFilters,
  useDataTableOptions,
} from '../../../reducers/useDataTableOptions';
import _, { Many } from 'lodash';
import { dayDiff, dow, today } from '../../../helpers/dateHelpers';
import {
  useCreateEvent,
  useDeleteEvent,
  useDeleteEvents,
  useUpdateEvent,
} from '../../../hooks/useEvents';
import { useEffect, useState } from 'react';

import DataTable from '../../../components/DataTable';
import Paginate from '../../../components/Paginate';
import { ValidationResult } from 'joi';
import { useDataTableEventsQuery } from '../../../features/event_data_table/gql/_gen_/events_table.gql';
import { useHotel } from '../../../context/hotelContext';
import { useUser } from '../../../context/userContext';

const Joi = require('joi').extend(require('@joi/date'));

function EventTable() {
  const objFields = ['name', 'start_date', 'end_date', 'details', 'id'];
  const { hotel } = useHotel();
  const { user } = useUser();

  const [options, setOptions] = useDataTableOptions({
    sort: { key: 'start_date', dir: SortDir.ASC },
  });
  const [dataRange, setDataRange] = useState<string[]>([]);
  const [tableData, setTableData] = useState<DataRow[]>([]);
  const [tableFilters, setTableFilters] = useState<TableFilters>({});
  const [totalPages, setTotalPages] = useState(1);
  const [paginatedEventObject, setPaginatedEventObject] =
    useState<PaginatedEventsObject>({
      pages: [],
      index: 0,
      totalEvents: 0,
      totalPages: 1,
    });
  const [page, setPage] = useState<number | undefined>(undefined);
  const PAGE_SIZE = 10;

  const deleteEvent = useDeleteEvent();
  const deleteEvents = useDeleteEvents();
  const updateEvent = useUpdateEvent();

  const {
    data: dataEvents,
    loading: loadingEvents,
    networkStatus: networkStatusEvents,
    refetch: refetchEvents,
  } = useDataTableEventsQuery({
    skip: !hotel?.hotel_id || !hotel?.brand_code,
    variables: {
      filters: {
        hotelId: hotel?.hotel_id || hotel?.brand_code,
      },
    },
    onCompleted: (data) => {
      if (data && data.hotelEvents) {
        const minDate = _.minBy(data.hotelEvents, 'start_date')?.start_date;
        const maxDate = _.maxBy(data.hotelEvents, 'end_date')?.end_date;
        if (minDate && maxDate) {
          setDataRange([minDate, maxDate]);
        }
      }
    },
  });

  const handleBulkUpsert = (eventIds: string[], changes: any) => {
    //TODO: implement bulk upsert
    return Promise.resolve();
  };

  const handleDelete = async (id: string | string[]) => {
    if (Array.isArray(id)) {
      return await deleteEvents[0]({ variables: { deleteEventId: id } });
    } else {
      return await deleteEvent[0]({ variables: { deleteEventId: id } });
    }
  };

  const handleDeleteAll = async () => {
    if (!dataEvents?.hotelEvents) return;
    return await deleteEvents[0]({
      variables: {
        deleteEventId: dataEvents.hotelEvents.map((e) => e?.id),
      },
    });
  };

  const handleUpdate = async (id: string, data: Event) => {
    return await updateEvent[0]({
      variables: {
        updateEventId: id,
        event: data,
      },
    });
  };

  const actions: Actions = {
    bulkUpsert: handleBulkUpsert,
    delete: handleDelete,
    deleteStatus: deleteEvent[1].loading || deleteEvents[1].loading,
    deleteAll: handleDeleteAll,
    networkStatus: networkStatusEvents,
    update: handleUpdate,
    updateStatus: updateEvent[1].loading,
  };

  const hookCE = useCreateEvent({
    callback: () => console.log('create event callback'),
  });

  useEffect(() => {
    if (hotel && (hotel.hotel_id || hotel.brand_code)) {
      refetchEvents({
        filters: {
          hotelId: hotel.hotel_id || hotel?.brand_code,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel]);

  useEffect(() => {
    if (dataEvents) {
      const rows = getRows(dataEvents);
      const paginatedEvents = paginateRows(
        rows.rows,
        rows.startEventIndex,
        PAGE_SIZE,
        page
      );
      setTableData(paginatedEvents.pages[paginatedEvents.index]);
      setPaginatedEventObject(paginatedEvents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEvents, loadingEvents, options, tableFilters]);

  useEffect(() => {
    setTotalPages(paginatedEventObject.totalPages);
    setPage(paginatedEventObject.index);
  }, [paginatedEventObject]);

  useEffect(() => {
    if (page !== undefined) {
      setTableData(paginatedEventObject.pages[page]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  function pageUp() {
    if (page !== undefined) {
      if (page < totalPages - 1) {
        setPage(page + 1);
      }
    }
  }

  function pageDown() {
    if (page !== undefined) {
      if (page >= 1) {
        setPage(page - 1);
      }
    }
  }

  const filterItems = (event: Event) => {
    return (
      filterDays(event, 'start_date') &&
      filterDays(event, 'end_date') &&
      filterString(event, 'name') &&
      filterString(event, 'details')
    );
  };

  const filterDays = (event: Event, attr: keyof Event) => {
    const filter = tableFilters[attr];
    if (filter) {
      const dowKey = dow(event[attr]).toLowerCase() as keyof typeof filter;
      return filter[dowKey] === true;
    }
    return true;
  };

  const filterString = (event: Event, attr: keyof Event) => {
    const filter = tableFilters[attr];
    if (filter) {
      return String(event[attr])
        .toLowerCase()
        .includes(String(filter).toLowerCase());
    }
    return true;
  };

  const getRows = (data: any): EventTableRows => {
    const rows: DataRow[] = [];
    if (data && data.hotelEvents) {
      const startEvent = {
        diff: 1000,
        index: 0,
      };
      _.orderBy(
        data.hotelEvents as Event[],
        [
          (event) =>
            event[options.sort.key as keyof Event] === null
              ? ''
              : event[options.sort.key as keyof Event].toLowerCase(),
        ],
        options.sort.dir as Many<boolean | 'desc' | 'asc'> | undefined
      )
        .filter(filterItems)
        .forEach((event: Event) => {
          const diff = dayDiff(event.start_date, today());
          if (Math.abs(diff) < startEvent.diff) {
            startEvent.diff = Math.abs(diff);
            startEvent.index = rows.length;
          }
          rows.push(_.pick(event, objFields) as DataRow);
        });
      return {
        rows: rows,
        startEventIndex: startEvent.index,
      };
    } else {
      return {
        rows: rows,
        startEventIndex: 0,
      };
    }
  };

  const emptyEvent = () => {
    return {
      hotel_id: hotel?.hotel_id || hotel?.brand_code,
      created_by_id: user?.id as string,
      name: '',
      start_date: '',
      end_date: '',
      details: '',
    };
  };

  const handleCreate = (fields: FormField[]) => {
    let event = emptyEvent();
    fields.forEach((f: FormField) => {
      event = {
        ...event,
        [f.name]: f.value,
      };
    });
    return hookCE[0]({
      variables: {
        event,
      },
      refetchQueries: ['DataTableEvents'],
    });
  };

  const splitRows = (rows: DataRow[], startIndex: number, pageSize: number) => {
    if (rows.length <= pageSize) {
      return [rows];
    }
    const secondHalf = rows.splice(startIndex);
    while (secondHalf.length < pageSize) {
      secondHalf.unshift(rows.pop() as DataRow);
    }
    return [rows, secondHalf];
  };

  const paginateRows = (
    rows: DataRow[],
    startIndex: number,
    pageSize: number,
    currentPage?: number
  ): PaginatedEventsObject => {
    const rowGroups = splitRows(rows, startIndex, pageSize);
    if (rowGroups.length === 1) {
      return {
        pages: [rows],
        index: 0,
        totalEvents: dataEvents?.hotelEvents?.length || 0,
        totalPages: 1,
      };
    }
    const pagesArr = [];
    while (rowGroups[0].length > 0) {
      pagesArr.unshift(rowGroups[0].splice(-pageSize));
    }
    const startPage = pagesArr.length;
    while (rowGroups[1].length > 0) {
      pagesArr.push(rowGroups[1].slice(0, pageSize));
      rowGroups[1].splice(0, pageSize);
    }
    return {
      pages: pagesArr,
      index: currentPage || startPage,
      totalEvents: dataEvents?.hotelEvents?.length || 0,
      totalPages: pagesArr.length,
    };
  };

  const formFields = objFields.map((key) => {
    return {
      bulk: [''].includes(key),
      id: '',
      label: key === 'id' ? 'Actions' : _.startCase(key),
      name: key,
      type: key.includes('date') ? 'date' : 'text',
      value: key.includes('date') ? today() : '',
    };
  });

  const validateEvent = (
    event: Event,
    type: 'bulk' | 'standard'
  ): ValidationResult => {
    const schema = Joi.object({
      name: Joi.string().required(),
      start_date: Joi.date().format('YYYY-MM-DD').required(),
      end_date: Joi.date()
        .format('YYYY-MM-DD')
        .min(Joi.ref('start_date'))
        .required(),
      details: Joi.string().allow(''),
    });

    return schema.tailor(type).validate(event);
  };

  return (
    <div className='w-full mt-2'>
      {loadingEvents ? (
        <p>Loading...</p>
      ) : (
        <div>
          <Paginate
            first={page || 0}
            last={totalPages}
            previous={pageDown}
            next={pageUp}
            total={paginatedEventObject.totalEvents || 0}
          />
          <DataTable
            actions={actions}
            dataRange={dataRange}
            fields={formFields as FormField[]}
            handleCreate={handleCreate}
            name='Event'
            options={options}
            rows={tableData}
            setOptions={setOptions}
            setTableFilters={setTableFilters}
            tableFilters={tableFilters}
            validation={validateEvent}
          />
        </div>
      )}
    </div>
  );
}

export default EventTable;
