import { FiCopy } from 'react-icons/fi';

interface BtnInsertNotesProps {
  handleInsert: () => void;
}

export const BtnInsertNotes = ({ handleInsert }: BtnInsertNotesProps) => {
  return (
    <button
      onClick={handleInsert}
      data-for='notes-toolbar-tooltip'
      data-tip='Insert notes from previous meeting'
    >
      <FiCopy />
    </button>
  );
};
