import * as ActionItems from '../gql/_gen_/action-item.gql';
import * as AgendaItems from '../gql/_gen_/agenda.gql';
import * as HotelTeam from '../gql/_gen_/hotel-team.gql';
import * as Meetings from '../gql/_gen_/meeting.gql';
import * as Types from '../../../graphql/types';

import { User, UserLookupType } from '../../../graphql/types';
import { createContext, useContext, useEffect, useState } from 'react';
import {
  useIsReport,
  useIsShared,
} from '../../reports/hooks/use-report-location';

import { FetchResult } from '@apollo/client';
import { ReportDataType } from '../../reports/context/report-data-context';
import { today } from '../../../helpers/dateHelpers';
import { useGetUserQuery } from '../../user/gql/_gen_/user.gql';
import { useHotel } from '../../../context/hotelContext';
import { useSaveMeeting } from '../hooks/use-save-meeting';
import { useUser } from '../../../context/userContext';

type MeetingContextProps = {
  addTeamMember: HotelTeam.AddTeamMemberMutationFn;
  addTeamMemberResults: HotelTeam.AddTeamMemberMutationResult;
  createActionItem: ActionItems.AddActionItemMutationFn;
  createActionItemResults: ActionItems.AddActionItemMutationResult;
  createAgendaItem: AgendaItems.AddAgendaItemMutationFn;
  createAgendaItemResults: AgendaItems.AddAgendaItemMutationResult;
  createHotelAgenda: AgendaItems.CreateHotelAgendaMutationFn;
  deleteMeeting: Meetings.RemoveMeetingMutationFn;
  deleteLoading: boolean;
  loading: boolean;
  loadingUpdateMeeting: boolean;
  logAgenda: Meetings.LogAgendaMutationFn;
  logAttendee: Meetings.LogAttendeeMutationFn;
  meeting?: Types.Meeting;
  meetingId?: string;
  meetingTools?: Types.MeetingService;
  meetingUser?: User;
  reactivateTeamMember: HotelTeam.ReactivateTeamMemberMutationFn;
  removeAgendaItem: AgendaItems.RemoveAgendaItemMutationFn;
  removeTeamMember: HotelTeam.RemoveTeamMemberMutationFn;
  saveMeeting: (
    options?: HandleSaveMeetingOptions
  ) => Promise<
    FetchResult<
      Meetings.AddMeetingMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  saveResults: Meetings.AddMeetingMutationResult;
  setMeeting: (meeting?: Types.Meeting) => void;
  setMeetingId: (meetingId?: string) => void;
  toggleAgendaItem: (item: Types.AgendaItem) => void;
  toggleAttend: (item: Types.TeamMember) => void;
  unlogAgenda: Meetings.UnlogAgendaMutationFn;
  unlogAttendee: Meetings.UnlogAttendeeMutationFn;
  updateActionItem: ActionItems.UpdateActionItemMutationFn;
  updateMeeting: Meetings.UpdateMeetingMutationFn;
  updateResults: Meetings.UpdateMeetingMutationResult;
};

type SaveMeetingOptions = Omit<
  Meetings.AddMeetingMutationVariables,
  'brandCode' | 'createdById'
>;

export interface HandleSaveMeetingOptions extends SaveMeetingOptions {
  data?: ReportDataType;
  meetingTools?: Types.MeetingService;
}

type MeetingProviderProps = { children: React.ReactNode };

const MeetingContext = createContext<MeetingContextProps | undefined>(
  undefined
);

function MeetingProvider({ children }: MeetingProviderProps) {
  const { isShared } = useIsShared();
  const { isReport } = useIsReport();
  const { hotel } = useHotel();
  const { user } = useUser();

  const [meeting, setMeeting] = useState<Types.Meeting | undefined>();
  const [meetingId, setMeetingId] = useState<string | undefined>();
  const [meetingTools, setMeetingTools] = useState<
    Types.MeetingService | undefined
  >();
  const [meetingUser, setMeetingUser] = useState<User>();

  const { handleSaveMeeting, saveResults, updateResults } = useSaveMeeting(
    meeting,
    setMeeting
  );

  Meetings.useGetMeetingByIdQuery({
    skip: !meetingId || isShared || meetingId === meeting?.id,
    variables: {
      meetingId: meetingId,
    },
    onCompleted: (data) => {
      if (data && data.getMeetingById) {
        setMeeting(data.getMeetingById);
      }
    },
  });

  const { loading, refetch } = Meetings.useGetMeetingByDateQuery({
    skip:
      !hotel ||
      !hotel.brand_code ||
      isReport ||
      isShared ||
      meetingId !== undefined,
    variables: {
      brandCode: hotel?.brand_code,
      meetingDate: today(),
    },
    onCompleted: (data) => {
      if (data && data.getMeetingByDate) {
        setMeeting(data.getMeetingByDate);
        data.getMeetingByDate.id && setMeetingId(data.getMeetingByDate.id);
      } else {
        setMeeting(undefined);
        setMeetingId(undefined);
      }
    },
  });

  useEffect(() => {
    if (!isReport) {
      if (!hotel) return;

      refetch({
        brandCode: hotel?.brand_code,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel]);

  Meetings.useMeetingToolsQuery({
    skip: !hotel?.brand_code || isShared,
    variables: {
      brandCode: hotel?.brand_code,
    },
    onCompleted: (data) => {
      if (data && data.meetingTools) {
        setMeetingTools(data.meetingTools);
      }
    },
  });

  useGetUserQuery({
    skip: !meeting?.createdById || isShared,
    variables: {
      lookup: {
        lookupColumn: UserLookupType.Id,
        lookupValue: meeting?.createdById as string,
      },
    },
    onCompleted: (data) => {
      if (data.getUser) setMeetingUser(data.getUser);
    },
  });

  const [addTeamMember, addTeamMemberResults] =
    HotelTeam.useAddTeamMemberMutation({
      refetchQueries: [Meetings.namedOperations.Query.MeetingTools],
    });

  const [createActionItem, createActionItemResults] =
    ActionItems.useAddActionItemMutation({
      refetchQueries: [Meetings.namedOperations.Query.MeetingTools],
    });

  const [createAgendaItem, createAgendaItemResults] =
    AgendaItems.useAddAgendaItemMutation({
      refetchQueries: [Meetings.namedOperations.Query.MeetingTools],
    });

  const [createHotelAgenda] = AgendaItems.useCreateHotelAgendaMutation({
    refetchQueries: [Meetings.namedOperations.Query.MeetingTools],
  });

  const [deleteMeeting, { loading: deleteLoading }] =
    Meetings.useRemoveMeetingMutation({
      onCompleted: () => setMeeting(undefined),
    });

  const [logAgenda] = Meetings.useLogAgendaMutation();
  const [unlogAgenda] = Meetings.useUnlogAgendaMutation();
  const [logAttendee] = Meetings.useLogAttendeeMutation();
  const [unlogAttendee] = Meetings.useUnlogAttendeeMutation();

  const toggleAgendaItem = async (item: Types.AgendaItem) => {
    if (meeting && meeting.id && meeting.agendaItems && item.id) {
      if (meeting.agendaItems.includes(item.id)) {
        await unlogAgenda({
          variables: {
            meetingId: meeting.id,
            agendaId: item.id,
          },
        });
      } else {
        await logAgenda({
          variables: {
            meetingId: meeting.id,
            agendaId: item.id,
          },
        });
      }
    } else if (!meeting?.id && item.id) {
      await handleSaveMeeting({
        agendaItems: [item.id],
      });
    }
  };

  const toggleAttend = async (item: Types.TeamMember) => {
    if (meeting && meeting.id && meeting.attendees && item.id) {
      if (meeting.attendees.includes(item.id)) {
        await unlogAttendee({
          variables: {
            meetingId: meeting.id,
            attendeeId: item.id,
          },
        });
      } else {
        await logAttendee({
          variables: {
            meetingId: meeting.id,
            attendeeId: item.id,
          },
        });
      }
    } else if (!meeting?.id && item.id) {
      await handleSaveMeeting({
        attendees: [item.id],
      });
    }
  };

  const [removeAgendaItem] = AgendaItems.useRemoveAgendaItemMutation({
    refetchQueries: [Meetings.namedOperations.Query.MeetingTools],
  });

  const [reactivateTeamMember] = HotelTeam.useReactivateTeamMemberMutation({
    refetchQueries: [Meetings.namedOperations.Query.MeetingTools],
  });

  const [removeTeamMember] = HotelTeam.useRemoveTeamMemberMutation({
    refetchQueries: [Meetings.namedOperations.Query.MeetingTools],
  });

  const [updateActionItem] = ActionItems.useUpdateActionItemMutation({
    refetchQueries: [Meetings.namedOperations.Query.MeetingTools],
  });

  const [updateMeeting, { loading: loadingUpdateMeeting }] =
    Meetings.useUpdateMeetingMutation({
      onCompleted: (data) => {
        if (data.updateMeeting) {
          setMeeting(data.updateMeeting);
          data.updateMeeting.id && setMeetingId(data.updateMeeting.id);
        }
      },
    });

  useEffect(() => {
    if (meetingTools && meetingTools.agendaItems) {
      if (
        meetingTools.agendaItems.filter((item) => item?.isDefault).length === 0
      ) {
        if (hotel?.brand_code && user?.id) {
          // Create the default agenda for the hotel
          createHotelAgenda({
            variables: {
              brandCode: hotel.brand_code,
              createdById: user.id,
            },
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingTools, hotel, user]);

  useEffect(() => {
    if (saveResults.data && saveResults.data.addMeeting) {
      setMeeting(saveResults.data.addMeeting);
      saveResults.data.addMeeting.id &&
        setMeetingId(saveResults.data.addMeeting.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveResults.data]);

  const value = {
    addTeamMember,
    addTeamMemberResults,
    createActionItem,
    createActionItemResults,
    createAgendaItem,
    createAgendaItemResults,
    createHotelAgenda,
    deleteMeeting,
    deleteLoading,
    loading,
    loadingUpdateMeeting,
    logAgenda,
    logAttendee,
    meeting,
    meetingId,
    meetingTools,
    meetingUser,
    reactivateTeamMember,
    removeAgendaItem,
    removeTeamMember,
    saveMeeting: handleSaveMeeting,
    saveResults,
    setMeeting,
    setMeetingId,
    toggleAgendaItem,
    toggleAttend,
    unlogAgenda,
    unlogAttendee,
    updateActionItem,
    updateMeeting,
    updateResults,
  };

  return (
    <MeetingContext.Provider value={value}>{children}</MeetingContext.Provider>
  );
}

function useMeeting() {
  const context = useContext(MeetingContext);
  if (context === undefined) {
    throw new Error(`useMeeting must be used within a MeetingProvider`);
  }
  return context;
}

export { MeetingProvider, useMeeting };
