import DataTableContainer from '../DataTableContainer';
import EventTable from '../tables/event/EventTable';

function Events() {
  return (
    <DataTableContainer>
      <EventTable />
    </DataTableContainer>
  );
}

export default Events;
