import 'react-quill/dist/quill.snow.css';

import { UseMeetingNotesProps, useMeetingNotes } from './hooks/use-notes';

import { Meeting } from '../../graphql/types';
import { NotesToolbar } from './components/notes-toolbar';
import ReactQuill from 'react-quill';
import { useIsShared } from '../reports/hooks/use-report-location';
import { useRef } from 'react';

interface EditorProps extends UseMeetingNotesProps {
  parentName: string;
  setMeeting?: (meeting: Meeting) => void;
}

export const Editor = (props: EditorProps) => {
  const editorRef = useRef<ReactQuill>(null);
  const { isShared } = useIsShared();
  const { parentName, ...rest } = props;
  const { handleInsert, handleSave, notes, setNotes, status } = useMeetingNotes(
    { ...rest, editorRef }
  );

  const modules = {
    ...(isShared ? { toolbar: false } : { toolbar: `#toolbar${parentName}` }),
  };

  return (
    <div>
      {isShared ? null : (
        <NotesToolbar
          handleInsert={handleInsert}
          handleSave={handleSave}
          parentName={parentName}
          status={status}
        />
      )}
      <ReactQuill
        className='h-[100%]'
        modules={modules}
        onBlur={handleSave}
        onChange={setNotes}
        readOnly={isShared}
        ref={editorRef}
        theme='snow'
        value={notes}
      />
    </div>
  );
};
