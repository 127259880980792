import {
  DAYS_OUT,
  State as Options,
  Action as OptionsAction,
  useDashboardOptions,
} from '../reducers/useDashboardOptions';
import {
  HotelSettings,
  TableAction,
  useTableSettings,
} from '../features/table-settings';
import {
  Action as RateParamsAction,
  defaultSubId,
  useRateShopParams,
} from '../reducers/useRateShopParams';
import { State as User, useUser } from './userContext';
import {
  between,
  dayDiff,
  initTableDates,
  today,
} from '../helpers/dateHelpers';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { Hotel } from '../graphql/types';
import { RateQueryParams } from '../graphql/types';
import { useHotel } from './hotelContext';
import { useIsShared } from '../features/reports/hooks/use-report-location';

export type TargetDate = {
  value: string;
};

export type ActiveCell = {
  row: number;
  col: number;
};

type DashboardContextProps = {
  activeCell: ActiveCell;
  brandCode?: string;
  daysOut: number;
  editEnabled: boolean;
  hotel: Hotel | undefined;
  hotelSettings: HotelSettings | undefined;
  options: Options;
  rateParams: RateQueryParams;
  setActiveCell: (params: ActiveCell) => void;
  setOptions: (payload: OptionsAction['payload']) => void;
  setRateParams: (payload: RateParamsAction['payload']) => void;
  setSortDir: (dir: 'asc' | 'desc') => void;
  setSortKey: (key: string) => void;
  setTableSettings: (action: TableAction) => void;
  setTargetDate: (tDate: TargetDate) => void;
  sortDir: 'asc' | 'desc';
  sortKey: string;
  targetDate: TargetDate | undefined;
  user: User;
  userId?: string;
};

const ROW_LIMIT_FOR_EDITING = 400;

const DashboardContext = createContext<DashboardContextProps | undefined>(
  undefined
);

export const DashboardProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { hotel, otaiSubs } = useHotel();
  const brandCode = hotel?.brand_code || undefined;
  const { user } = useUser();
  const { isShared } = useIsShared();
  const userId = user?.id || undefined;
  const [hotelSettings, setHotelSettings] = useState<
    HotelSettings | undefined
  >();
  const [options, dispatchOptions] = useDashboardOptions(hotel);
  const [rateParams, dispatchParams] = useRateShopParams(otaiSubs);
  const [activeCell, setActiveCell] = useState<ActiveCell>({
    row: 0,
    col: 0,
  });
  const [sortDir, setSortDir] = useState<'asc' | 'desc'>('asc');
  const [sortKey, setSortKey] = useState('stay_date');
  const [tableSettings, dispatchSettings] = useTableSettings();
  const [targetDate, setTargetDate] = useState<TargetDate | undefined>();

  useEffect(() => {
    setRateParams({ subscriptionId: defaultSubId(otaiSubs) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otaiSubs]);

  useEffect(() => {
    if (options.startDate && options.endDate && targetDate) {
      if (!between(targetDate.value, options.startDate, options.endDate)) {
        setOptions(initTableDates());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDate]);

  useEffect(() => {
    if (hotel && hotel.brand_code && tableSettings) {
      setHotelSettings(tableSettings[hotel.brand_code]);
    }
  }, [hotel, tableSettings]);

  const isEditEnabled = useMemo(() => {
    const { startDate, endDate, dateSegment, snapshotDate } = options;
    return (
      dateSegment === 'day' &&
      dayDiff(startDate, endDate) <= ROW_LIMIT_FOR_EDITING &&
      snapshotDate === today() &&
      !isShared
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.startDate,
    options.endDate,
    options.dateSegment,
    options.snapshotDate,
    isShared,
  ]);

  const setOptions = (payload: OptionsAction['payload']) => {
    dispatchOptions({ type: 'UPDATE_OPTIONS', payload });
  };

  const setRateParams = (payload: RateParamsAction['payload']) => {
    dispatchParams({ type: 'UPDATE_PARAMS', payload });
  };

  const setTableSettings = (action: TableAction) => {
    dispatchSettings(action);
  };

  const contextValue = {
    activeCell,
    brandCode,
    daysOut: DAYS_OUT,
    editEnabled: isEditEnabled,
    hotel,
    hotelSettings,
    options,
    rateParams,
    setActiveCell,
    setOptions,
    setRateParams,
    setSortDir,
    setSortKey,
    setTableSettings,
    setTargetDate,
    sortDir,
    sortKey,
    targetDate,
    user,
    userId,
  };
  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};
