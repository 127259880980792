import { NotFound } from './components/not-found';
import Report from './components/report';
import { ReportLoading } from './components/report-loading';
import { useEffect } from 'react';
import { useHotel } from '../../context/hotelContext';
import { useMeeting } from '../meetings/hooks/use-meeting';
import { useParams } from 'react-router-dom';
import { useStoredData } from '../meetings/hooks/use-stored-data';
import { useTrackMeetingEvent } from '../tracking/hooks/use-track-event';

const SharedReport = () => {
  const { setBrandCode } = useHotel();
  const { meetingId } = useParams<{ meetingId: string }>();
  const { meeting, loadingId } = useMeeting({
    meetingId,
  });
  const { data } = useStoredData({
    bucket: meeting?.bucket,
    key: meeting?.bucketKey,
  });

  const track = useTrackMeetingEvent({ meeting, page: 'shared_report' });

  useEffect(() => {
    if (meeting) {
      setBrandCode(meeting.brandCode as string);
      track('view_report', 'view_report_loaded');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting]);

  if (!meeting && !loadingId) {
    return (
      <NotFound
        heading={'Meeting not found.'}
        meetingId={meetingId}
        message={'we could not find a meeting with id'}
      />
    );
  }

  if (data && data.data && meeting) {
    return <Report data={data} meeting={meeting} meetingLoading={loadingId} />;
  } else {
    return <ReportLoading />;
  }
};

export { SharedReport };
