import { GET_BESTREV } from '../gql/gqlBestrev';
import { useQuery } from '@apollo/client';

export type UseHookArgs = {
  brandCode: string;
  snapshotDate: string;
  callback: () => void;
  useCache?: boolean;
};

export function useBestrev(a: UseHookArgs) {
  const { brandCode, snapshotDate, callback, useCache } = a;
  return useQuery(GET_BESTREV, {
    variables: {
      brandCode,
      snapshotDate,
      useCache,
    },
    skip: !brandCode || !snapshotDate,
    onCompleted: () => callback(),
  });
}
