import {
  ActionItem,
  ActionItemStatus,
  AgendaItemNote,
  Maybe,
  Meeting,
  MeetingCategory,
  MeetingEmail,
  MeetingService,
  TeamMember,
  TeamMemberActive,
} from '../../../graphql/types';
import {
  betweenOrEqual,
  displayDate,
  fromToday,
  today,
} from '../../../helpers/dateHelpers';

import { ReportUrls } from './report-urls';

export * as Email from './email';

export type CreateMailtoArgs = {
  from: string;
  meeting: Meeting;
  meetingTools: MeetingService;
};

export const createMailtoLink = ({
  from,
  meeting,
  meetingTools,
}: CreateMailtoArgs) => {
  const mailUrl =
    `mailto:${sendTo(meeting, meetingTools.hotelTeams)}?cc=${from}&subject=` +
    encodeURIComponent(subject(meeting));

  const reportLink = shareLink(meeting.id!);

  const messageBody =
    `<b>Please click the <a href="${reportLink}">link</a> to view your report:</b>` +
    '<br>' +
    reportLink +
    '\n' +
    meetingDetails(meeting, meetingTools);

  return { mailUrl, messageBody };
};

const activeTeam = (member: Maybe<TeamMember>) => {
  return member?.active === TeamMemberActive.Active;
};

const emailPref = (member: Maybe<TeamMember>, meeting: Maybe<Meeting>) => {
  return (
    member?.emailPref === MeetingEmail.All ||
    member?.emailPref === meeting?.category
  );
};

const createActionListItems = (
  actionItems: MeetingService['actionItems'],
  meetingTools: MeetingService
) => {
  if (actionItems) {
    return actionItems
      .map((actionItem) => {
        return `<li>${actionItem?.name} <b>Status:</b> ${
          actionItem?.status
        } <b>Due:</b> ${displayDate(
          actionItem?.dueDate as string
        )} <b>Assignee:</b> ${
          getTeamMember(actionItem?.assignedToId!, meetingTools)?.name
        }</li>`;
      })
      .filter((item) => item !== undefined)
      .join('\n');
  }
};

const getTeamMember = (userId: string, meetingTools: MeetingService) => {
  return meetingTools?.hotelTeams?.find((item) => String(item?.id) === userId);
};

const filterActionItemTodo = (actionItem: Maybe<ActionItem>) => {
  return (
    actionItem?.status === ActionItemStatus.InProgress ||
    actionItem?.status === ActionItemStatus.NotStarted
  );
};

const filterActionItemComplete = (actionItem: Maybe<ActionItem>) => {
  return (
    actionItem?.status === ActionItemStatus.Completed &&
    betweenOrEqual(actionItem.statusUpdated!, fromToday(-7), today())
  );
};

const listActionItems = (meetingTools: MeetingService) => {
  let toDo;
  let done;
  const todoActionItems =
    meetingTools.actionItems?.filter(filterActionItemTodo);
  const doneActionItems = meetingTools.actionItems?.filter(
    filterActionItemComplete
  );
  toDo = createActionListItems(todoActionItems, meetingTools);
  done = createActionListItems(doneActionItems, meetingTools);

  if (toDo && done) {
    return `${toDo}${'\n'}${done}`;
  } else if (toDo) {
    return `${toDo}`;
  } else if (done) {
    return `${done}`;
  }
  return '';
};

const listAgenda = (
  ids: string[],
  meetingTools: MeetingService,
  itemNotes?: Maybe<Maybe<AgendaItemNote>[]>
) => {
  if (ids.length > 0) {
    const items = ids
      .map((id) => {
        const agendaItem = meetingTools.agendaItems?.find(
          (item) => String(item?.id) === id
        );
        const note = itemNotes?.find((item) => String(item?.itemId) === id);
        return `<li>${agendaItem?.name} ${
          note && note.notes ? `<ul><li>${note.notes}</li></ul>` : ''
        }</li>`;
      })
      .filter((item) => item !== undefined)
      .join('\n');
    return `<ul>${items}</ul>`;
  }
  return '';
};

const listAttendees = (ids: string[], meetingTools: MeetingService) => {
  if (ids.length > 0) {
    const items = ids
      .map((id) => {
        const attendee = meetingTools?.hotelTeams?.find(
          (item) => String(item?.id) === id
        );
        return `<li>${attendee?.name}</li>`;
      })
      .filter((item) => item !== undefined)
      .join('\n');
    return `<ul>${items}</ul>`;
  }
  return '';
};

const meetingDetails = (
  meeting: Maybe<Meeting>,
  meetingTools: Maybe<MeetingService>
) => {
  let details = '';
  if (!meeting || !meetingTools) {
    return details;
  }

  if (meeting.category === MeetingCategory.Weekly) {
    details =
      details +
      `<h3>--- Agenda ---</h3>` +
      '\n' +
      listAgenda(
        meeting.agendaItems as string[],
        meetingTools,
        meeting.agendaItemNotes
      ) +
      '\n' +
      `<h3>--- Action Items ---</h3>` +
      '\n' +
      listActionItems(meetingTools) +
      '\n' +
      `<h3>--- Attendees ---</h3>` +
      '\n' +
      listAttendees(meeting.attendees as string[], meetingTools) +
      '\n';
  }

  details = details + `<h3>--- Notes ---</h3>\n` + notesText(meeting) + '\n';

  return details;
};

const notesText = (meeting: Meeting) => {
  return meeting.notes;
};

const sendTo = (meeting: Maybe<Meeting>, to?: Maybe<Maybe<TeamMember>[]>) => {
  if (to) {
    return to
      .filter(activeTeam)
      .filter((member) => emailPref(member, meeting))
      .map((member) => member?.email)
      .join(';');
  }
  return '';
};

const shareLink = (meetingId: string) => {
  return ReportUrls.createShareUrl(meetingId);
};

const subject = (meeting: Meeting) => {
  const { brandCode, meetingDate } = meeting;
  return `${brandCode} | RM ${subjectCategory(
    meeting
  )} Update for ${displayDate(meetingDate || 'today')}`;
};

const subjectCategory = (meeting: Meeting) => {
  switch (meeting.category) {
    case MeetingCategory.Weekly:
      return 'Weekly';
    case MeetingCategory.Adhoc:
      return 'Ad Hoc';
    default:
      return 'Daily';
  }
};
