import {
  Maybe,
  Meeting,
  TeamMember,
  TeamMemberActive,
} from '../../../graphql/types';
import { useEffect, useState } from 'react';

import { FiUserPlus } from 'react-icons/fi';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { clsx } from 'clsx';
import { useIsShared } from '../../reports/hooks/use-report-location';
import { useToggleAttend } from '../hooks/use-toggle-attend';
import { useTrackMeetingEvent } from '../../tracking/hooks/use-track-event';

type TeamListItemProps = {
  data: Maybe<TeamMember>;
  meeting?: Meeting;
  toggleActive: (member: TeamMember) => Promise<void>;
  setExpanded: React.Dispatch<
    React.SetStateAction<Maybe<TeamMember> | undefined>
  >;
};

const TeamListItem = ({
  data,
  meeting,
  toggleActive,
  setExpanded,
}: TeamListItemProps) => {
  const { isShared } = useIsShared();
  const { loading, toggleAttend } = useToggleAttend(meeting);
  const [attended, setAttended] = useState(false);
  const track = useTrackMeetingEvent({ meeting, page: 'team_list_item' });

  useEffect(() => {
    if (meeting?.attendees && data?.id && !loading) {
      const isAttended = meeting.attendees.includes(data.id);
      if (isAttended !== attended) {
        setAttended(isAttended);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, meeting?.attendees]);

  const handleAttendChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (data) {
      setAttended(e.target.checked);
      toggleAttend(data);
      track('toggle_attended', `teamId_${data.id}_${e.target.checked}`);
    }
  };

  return (
    <li key={data?.id} className='py-2'>
      <div className='relative'>
        <div className='flex justify-between'>
          <div className='flex'>
            <h3 className='text-sm font-semibold text-gray-800'>
              <button
                onClick={() => setExpanded(data)}
                disabled={isShared}
                className={clsx(
                  attended
                    ? `text-blue-800 font-extrabold focus:outline-none decoration-2 decoration-gray-400`
                    : `hover:underline focus:outline-none`
                )}
              >
                {data?.name}
              </button>
              <span className='text-blue-500 ml-2 text-xs'>
                {data?.emailPref}
              </span>
            </h3>
            {data?.active && (
              <button
                className={clsx(
                  data.active === TeamMemberActive.Active
                    ? 'text-red-500'
                    : 'text-green-500',
                  'ml-1.5'
                )}
                disabled={isShared}
                onClick={() => toggleActive(data)}
              >
                {data.active === TeamMemberActive.Active ? (
                  <MdOutlineDeleteOutline />
                ) : (
                  <FiUserPlus />
                )}
              </button>
            )}
          </div>
          <input
            type='checkbox'
            checked={attended}
            disabled={isShared}
            onChange={handleAttendChange}
          />
        </div>
        <pre>
          <p className='mt-1 text-sm text-gray-600 line-clamp-2 font-sans'>
            {data?.email}
          </p>
        </pre>
      </div>
    </li>
  );
};

export { TeamListItem };
