import {
  firstOfMonth,
  formatDate,
  subtract,
  today,
  yesterday,
} from '../helpers/dateHelpers';

import ReactTooltip from 'react-tooltip';
import { RmData } from '../graphql/types';
import Table from '../components/Table';
import { tableData } from './dataLastNightTable';

type Props = {
  data?: RmData[];
  lastNight?: string;
};

export const LastNightTooltip = () => {
  return (
    <ReactTooltip id='last-night-summary' aria-haspopup='true'>
      <div>
        <p>
          <strong>Last Night Summary Table</strong>
        </p>
        <br />
        <ul>
          <li>
            MTD - This is the total from the 1st of the month up to Last Night
          </li>
          <li>
            MTD LY - This is the total from the same day of the month last year
            up to last night last year.
          </li>
        </ul>
        <br />
        <p>
          <em>{`Example. If today is ${formatDate(
            today(),
            'MMMM D, YYYY'
          )}`}</em>
        </p>
        <ul>
          <li>{`MTD covers ${formatDate(firstOfMonth({}))} - ${formatDate(
            yesterday()
          )} for actuals`}</li>
          <li>
            {` MTD LY covers ${formatDate(
              firstOfMonth({ daysOut: -365 })
            )} - ${formatDate(
              subtract(today(), 'days', 366)
            )} for actuals (not same point
            in time)`}
          </li>
        </ul>
      </div>
    </ReactTooltip>
  );
};

const LastNightTable = ({ data, lastNight = yesterday() }: Props) => {
  return <Table {...tableData(lastNight, data)} />;
};

export default LastNightTable;
