/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PortfolioWidgetQueryVariables = Types.Exact<{
  args: Types.RmDataArgs;
  brandCode?: Types.InputMaybe<Types.Scalars['String']>;
  startDate?: Types.InputMaybe<Types.Scalars['String']>;
  endDate?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type PortfolioWidgetQuery = { __typename?: 'Query', liveData?: Array<{ __typename?: 'LiveData', group_delta_intraday?: number | null, transient_delta_intraday?: number | null, stay_date?: any | null } | null> | null, rmData?: Array<{ __typename?: 'RmData', delta_1day?: number | null, delta_3day?: number | null, stay_date?: any | null } | null> | null };

export type AddHotelToPortfolioMutationVariables = Types.Exact<{
  portfolioId: Types.Scalars['String'];
  brandCode: Types.Scalars['String'];
}>;


export type AddHotelToPortfolioMutation = { __typename?: 'Mutation', addHotelToPortfolio?: { __typename?: 'Portfolio', created_at?: string | null, id: string, updated_at?: string | null, user_id: string, hotels?: Array<{ __typename?: 'Hotel', brand_code: any, hotel_id?: number | null, name?: string | null } | null> | null, user?: { __typename?: 'User', id?: string | null, email?: string | null } | null } | null };

export type RemoveHotelFromPortfolioMutationVariables = Types.Exact<{
  portfolioId: Types.Scalars['String'];
  brandCode: Types.Scalars['String'];
}>;


export type RemoveHotelFromPortfolioMutation = { __typename?: 'Mutation', removeHotelFromPortfolio?: { __typename?: 'Portfolio', id: string, hotels?: Array<{ __typename?: 'Hotel', brand_code: any, name?: string | null } | null> | null } | null };

export type CreatePortfolioMutationVariables = Types.Exact<{
  portfolio: Types.CreateUpdatePortfolioInput;
}>;


export type CreatePortfolioMutation = { __typename?: 'Mutation', createPortfolio?: { __typename?: 'Portfolio', id: string, user_id: string, hotels?: Array<{ __typename?: 'Hotel', brand_code: any, name?: string | null } | null> | null, user?: { __typename?: 'User', email?: string | null, id?: string | null, name?: string | null } | null } | null };


export const PortfolioWidgetDocument = gql`
    query PortfolioWidget($args: RmDataArgs!, $brandCode: String, $startDate: String, $endDate: String) {
  liveData(brandCode: $brandCode, startDate: $startDate, endDate: $endDate) {
    group_delta_intraday
    transient_delta_intraday
    stay_date
  }
  rmData(args: $args) {
    delta_1day
    delta_3day
    stay_date
  }
}
    `;

/**
 * __usePortfolioWidgetQuery__
 *
 * To run a query within a React component, call `usePortfolioWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioWidgetQuery({
 *   variables: {
 *      args: // value for 'args'
 *      brandCode: // value for 'brandCode'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function usePortfolioWidgetQuery(baseOptions: Apollo.QueryHookOptions<PortfolioWidgetQuery, PortfolioWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PortfolioWidgetQuery, PortfolioWidgetQueryVariables>(PortfolioWidgetDocument, options);
      }
export function usePortfolioWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortfolioWidgetQuery, PortfolioWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PortfolioWidgetQuery, PortfolioWidgetQueryVariables>(PortfolioWidgetDocument, options);
        }
export type PortfolioWidgetQueryHookResult = ReturnType<typeof usePortfolioWidgetQuery>;
export type PortfolioWidgetLazyQueryHookResult = ReturnType<typeof usePortfolioWidgetLazyQuery>;
export type PortfolioWidgetQueryResult = Apollo.QueryResult<PortfolioWidgetQuery, PortfolioWidgetQueryVariables>;
export const AddHotelToPortfolioDocument = gql`
    mutation AddHotelToPortfolio($portfolioId: String!, $brandCode: String!) {
  addHotelToPortfolio(portfolioId: $portfolioId, brandCode: $brandCode) {
    created_at
    hotels {
      brand_code
      hotel_id
      name
    }
    id
    updated_at
    user_id
    user {
      id
      email
    }
  }
}
    `;
export type AddHotelToPortfolioMutationFn = Apollo.MutationFunction<AddHotelToPortfolioMutation, AddHotelToPortfolioMutationVariables>;

/**
 * __useAddHotelToPortfolioMutation__
 *
 * To run a mutation, you first call `useAddHotelToPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHotelToPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHotelToPortfolioMutation, { data, loading, error }] = useAddHotelToPortfolioMutation({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useAddHotelToPortfolioMutation(baseOptions?: Apollo.MutationHookOptions<AddHotelToPortfolioMutation, AddHotelToPortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddHotelToPortfolioMutation, AddHotelToPortfolioMutationVariables>(AddHotelToPortfolioDocument, options);
      }
export type AddHotelToPortfolioMutationHookResult = ReturnType<typeof useAddHotelToPortfolioMutation>;
export type AddHotelToPortfolioMutationResult = Apollo.MutationResult<AddHotelToPortfolioMutation>;
export type AddHotelToPortfolioMutationOptions = Apollo.BaseMutationOptions<AddHotelToPortfolioMutation, AddHotelToPortfolioMutationVariables>;
export const RemoveHotelFromPortfolioDocument = gql`
    mutation RemoveHotelFromPortfolio($portfolioId: String!, $brandCode: String!) {
  removeHotelFromPortfolio(portfolioId: $portfolioId, brandCode: $brandCode) {
    id
    hotels {
      brand_code
      name
    }
  }
}
    `;
export type RemoveHotelFromPortfolioMutationFn = Apollo.MutationFunction<RemoveHotelFromPortfolioMutation, RemoveHotelFromPortfolioMutationVariables>;

/**
 * __useRemoveHotelFromPortfolioMutation__
 *
 * To run a mutation, you first call `useRemoveHotelFromPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHotelFromPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHotelFromPortfolioMutation, { data, loading, error }] = useRemoveHotelFromPortfolioMutation({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useRemoveHotelFromPortfolioMutation(baseOptions?: Apollo.MutationHookOptions<RemoveHotelFromPortfolioMutation, RemoveHotelFromPortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveHotelFromPortfolioMutation, RemoveHotelFromPortfolioMutationVariables>(RemoveHotelFromPortfolioDocument, options);
      }
export type RemoveHotelFromPortfolioMutationHookResult = ReturnType<typeof useRemoveHotelFromPortfolioMutation>;
export type RemoveHotelFromPortfolioMutationResult = Apollo.MutationResult<RemoveHotelFromPortfolioMutation>;
export type RemoveHotelFromPortfolioMutationOptions = Apollo.BaseMutationOptions<RemoveHotelFromPortfolioMutation, RemoveHotelFromPortfolioMutationVariables>;
export const CreatePortfolioDocument = gql`
    mutation CreatePortfolio($portfolio: CreateUpdatePortfolioInput!) {
  createPortfolio(portfolio: $portfolio) {
    hotels {
      brand_code
      name
    }
    id
    user_id
    user {
      email
      id
      name
    }
  }
}
    `;
export type CreatePortfolioMutationFn = Apollo.MutationFunction<CreatePortfolioMutation, CreatePortfolioMutationVariables>;

/**
 * __useCreatePortfolioMutation__
 *
 * To run a mutation, you first call `useCreatePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPortfolioMutation, { data, loading, error }] = useCreatePortfolioMutation({
 *   variables: {
 *      portfolio: // value for 'portfolio'
 *   },
 * });
 */
export function useCreatePortfolioMutation(baseOptions?: Apollo.MutationHookOptions<CreatePortfolioMutation, CreatePortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePortfolioMutation, CreatePortfolioMutationVariables>(CreatePortfolioDocument, options);
      }
export type CreatePortfolioMutationHookResult = ReturnType<typeof useCreatePortfolioMutation>;
export type CreatePortfolioMutationResult = Apollo.MutationResult<CreatePortfolioMutation>;
export type CreatePortfolioMutationOptions = Apollo.BaseMutationOptions<CreatePortfolioMutation, CreatePortfolioMutationVariables>;
export const namedOperations = {
  Query: {
    PortfolioWidget: 'PortfolioWidget'
  },
  Mutation: {
    AddHotelToPortfolio: 'AddHotelToPortfolio',
    RemoveHotelFromPortfolio: 'RemoveHotelFromPortfolio',
    CreatePortfolio: 'CreatePortfolio'
  }
}