import { DailyGroupData, Group } from '../types/Group';

import { CellObject } from '../features/overview-table/hooks/use-table';
import ReactDOMServer from 'react-dom/server';
import _ from 'lodash';
import { displayDate } from '../helpers/dateHelpers';
import { displayRate } from './baseFormat';

type RendererProps = {
  cell: CellObject;
};

type GroupCellProps = {
  cellData: string | number;
  inactive: boolean;
};

function totalCurrentGroups(groups: DailyGroupData) {
  let comm = 0;
  let sold = 0;
  let rem = 0;
  comm = _.sumBy(groups.details, function (o: Group) {
    if (o.is_past === true) {
      return 0;
    } else {
      return o.group_rooms_committed;
    }
  });
  sold = _.sumBy(groups.details, function (o: Group) {
    if (o.is_past === true) {
      return 0;
    } else {
      return o.group_rooms_sold;
    }
  });
  rem = _.sumBy(groups.details, function (o: Group) {
    if (o.is_past === true) {
      return 0;
    } else {
      return o.group_rooms_remaining;
    }
  });

  return { comm, sold, rem };
}

const GroupTableCell = ({ inactive, cellData }: GroupCellProps) => {
  if (inactive === true) {
    return (
      <td className='border p-2 text-gray-500'>
        <s>{cellData}</s>
      </td>
    );
  } else {
    return <td className='border p-2'>{cellData}</td>;
  }
};

const GroupCellTooltip = (cell: CellObject): JSX.Element => {
  const { data } = cell.meta;
  const groups = data as DailyGroupData;

  if (groups) {
    const { comm, sold, rem } = totalCurrentGroups(groups);
    return (
      <table className='border border-collapse text-center'>
        <caption>Groups {displayDate(cell.stayDate)}</caption>
        <thead>
          <tr>
            <th className='border p-2'>Rate Code</th>
            <th className='border p-2'>Group Description</th>
            <th className='border p-2'>Comm.</th>
            <th className='border p-2'>Sold</th>
            <th className='border p-2'>Rem.</th>
            <th className='border p-2'>Lowest Rate</th>
            <th className='border p-2'>Snapshot Date</th>
          </tr>
        </thead>
        <tbody>
          {_.orderBy(groups.details, 'description').map((group: Group) => {
            const {
              is_past,
              rate_code,
              description,
              group_rooms_committed,
              group_rooms_sold,
              group_rooms_remaining,
              lowest_group_rate,
              snapshot_date,
              stay_date,
            } = group;
            return (
              <tr key={`${rate_code}-${stay_date}`}>
                <GroupTableCell inactive={is_past} cellData={rate_code} />
                <GroupTableCell inactive={is_past} cellData={description} />
                <GroupTableCell
                  inactive={is_past}
                  cellData={group_rooms_committed}
                />
                <GroupTableCell
                  inactive={is_past}
                  cellData={group_rooms_sold}
                />
                <GroupTableCell
                  inactive={is_past}
                  cellData={group_rooms_remaining}
                />
                <GroupTableCell
                  inactive={is_past}
                  cellData={displayRate(lowest_group_rate)}
                />
                <GroupTableCell
                  inactive={is_past}
                  cellData={displayDate(snapshot_date)}
                />
              </tr>
            );
          })}
          <tr>
            <td className='border p-2' colSpan={2}>
              Totals (Active)
            </td>
            <td className='border p-2'>{comm}</td>
            <td className='border p-2'>{sold}</td>
            <td className='border p-2'>{rem}</td>
          </tr>
        </tbody>
      </table>
    );
  } else {
    return <></>;
  }
};

const GroupCell = (cell: CellObject) => {
  return (
    <>
      <span
        data-html={true}
        data-type='light'
        data-tip={ReactDOMServer.renderToString(<GroupCellTooltip {...cell} />)}
      >
        {cell.value === 0 ? null : cell.displayValue}
      </span>
    </>
  );
};

export default function RenderGroupDetails({ cell }: RendererProps) {
  return <GroupCell {...cell} />;
}
